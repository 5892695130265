import { ax } from "@/core";
import { EditProjectRequestActivityModal } from "@/modals/sales/edit-project-request-activity-modal";
import type { ProjectRequestTimelineActivityEntry } from "@msuite/katana";
import {
	Box,
	HStack,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Portal,
	Text,
	UnstyledIconButton,
	toast,
	useAsyncModal,
	useDisclosure,
	useUIContext,
} from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { IoEllipsisVertical, IoTrash } from "react-icons/io5";
import { TbEdit } from "react-icons/tb";

/** Props Interface */
interface ActivityMenuProps {
	projectRequestId: string;
	entry: ProjectRequestTimelineActivityEntry & { id: string };
}

export const ActivityMenu: FC<ActivityMenuProps> = ({ projectRequestId, entry }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const asyncModal = useAsyncModal();
	const editProjectRequestActivityModal = useDisclosure();

	/** Functions */
	async function handleOnDelete() {
		try {
			const isConfirmed = await asyncModal({
				headerText: "Aktivität löschen",
				bodyText:
					"Möchten Sie diese Aktivität wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.",
				confirmButtonText: "Löschen",
				cancelButtonText: "abbrechen",
			});
			if (!isConfirmed) return;
			await ax.delete(`/v2/project-requests/${projectRequestId}/activity/${entry.id}`);
		} catch (error) {
			console.error(error);
			toast.error("Fehler beim Löschen der Aktivität");
		}
	}

	/** Render */
	return (
		<Fragment>
			{editProjectRequestActivityModal.isOpen && (
				<EditProjectRequestActivityModal
					projectRequestId={projectRequestId}
					activityId={entry.id}
					isOpen={editProjectRequestActivityModal.isOpen}
					onClose={editProjectRequestActivityModal.onClose}
				/>
			)}
			<Box
				position="absolute"
				top={0}
				right={0}
				padding={2}
			>
				<Menu placement="bottom-end">
					<MenuButton>
						<UnstyledIconButton
							icon={<IoEllipsisVertical />}
							aria-label="menu"
							size="xs"
							variant="ghost"
						/>
					</MenuButton>
					<Portal>
						<MenuList>
							<MenuItem
								onClick={editProjectRequestActivityModal.onOpen}
								closeOnSelect
							>
								<HStack spacing={2}>
									<Text color={colors.gray}>
										<TbEdit />
									</Text>
									<Text>Aktivität bearbeiten</Text>
								</HStack>
							</MenuItem>
							<MenuDivider />
							<MenuItem onClick={handleOnDelete}>
								<HStack spacing={2}>
									<Text color={colors.red}>
										<IoTrash />
									</Text>
									<Text color={colors.red}>Aktivität löschen</Text>
								</HStack>
							</MenuItem>
						</MenuList>
					</Portal>
				</Menu>
			</Box>
		</Fragment>
	);
};
