import { Icon } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import type { ConstructionSiteDocument } from "@msuite/katana";
import { Box, HStack, Text, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbFolderFilled } from "react-icons/tb";

/** Props Interface */
interface DocumentNameProps {
	document: ConstructionSiteDocument;
}

export const DocumentName: FC<DocumentNameProps> = ({ document }) => {
	/** Context */
	const { colors } = useUIContext();
	const extension = document?.file_name?.split(".").pop();

	/** Render */
	if (document.file_name)
		return (
			<HStack spacing={2}>
				<Icon {...getFileTypeIconProps({ extension })} />
				<Text>{document.file_name}</Text>
			</HStack>
		);

	let folderName = document.documents?.at(0)?.file_path.split("/").at(3);
	if (folderName === ".") folderName = "Unbenannt";

	/** Render */
	return (
		<HStack spacing={2}>
			<Box color={colors.yellow}>
				<TbFolderFilled />
			</Box>
			<Text>{folderName}</Text>
		</HStack>
	);
};
