import type { EmployeeAuthorization } from "@msuite/katana";
import { getEmployeeAuthorizationString } from "@msuite/katana";
import { FormControl, FormLabel, Switch, VStack } from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { type IConstructionSiteContactForm, employeeAuthorizations } from "./schema";

/** Props Interface */
interface ContactAuthorizationsProps {
	index: number;
}

export const ContactAuthorizations: FC<ContactAuthorizationsProps> = ({ index }) => {
	/** Hooks */
	const methods = useFormContext<IConstructionSiteContactForm>();
	const authorizations = useWatch({
		name: `contacts.${index}.authorizations`,
		control: methods.control,
	});

	if (!authorizations) return null;

	/** Functions */
	function handleToggleAuthorization(authorization: EmployeeAuthorization) {
		const currentAuthorizations = methods.getValues(`contacts.${index}.authorizations`);
		const isCurrentlyIncluded = currentAuthorizations.includes(authorization);
		const newAuthorizations = isCurrentlyIncluded
			? currentAuthorizations.filter((a) => a !== authorization)
			: [...currentAuthorizations, authorization];
		methods.setValue(`contacts.${index}.authorizations`, newAuthorizations);
	}

	/** Render */
	return (
		<VStack>
			<FormControl>
				<FormLabel>Vollmachten</FormLabel>
				<VStack spacing={4}>
					{employeeAuthorizations.map((authorization) => (
						<Switch
							key={authorization}
							isChecked={authorizations.includes(authorization)}
							onChange={() => handleToggleAuthorization(authorization)}
						>
							{getEmployeeAuthorizationString(authorization)}
						</Switch>
					))}
				</VStack>
			</FormControl>
		</VStack>
	);
};
