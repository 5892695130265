import { useEditConstructionSiteContactModalContext } from "@/modals/construction-sites/edit-construction-site-contact-modal/context";
import type { ConstructionSiteContact } from "@msuite/katana";
import { contactStatusArray, generateId, getContactStatusLabel } from "@msuite/katana";
import {
	Box,
	Collapse,
	Collapser,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	HStack,
	IconButton,
	Input,
	Select,
	Switch,
	Text,
	VStack,
	useDisclosure,
	useUIContext,
} from "@msuite/picasso";
import { type FC, useEffect } from "react";
import {
	type FieldArrayWithId,
	type useFieldArray,
	useFormContext,
	useWatch,
} from "react-hook-form";
import { TbEye, TbEyeOff, TbTrash } from "react-icons/tb";
import { ContactAuthorizations } from "./contact-authorizations";
import { FormError, type IConstructionSiteContactForm } from "./schema";

interface ConstructionSiteContactContactsFormProps {
	fieldArray: ReturnType<typeof useFieldArray<IConstructionSiteContactForm, "contacts", "id">>;
	isSingleEntry?: boolean;
}

export const ConstructionSiteContactContactsForm: FC<ConstructionSiteContactContactsFormProps> = ({
	fieldArray,
	isSingleEntry,
}) => {
	/** Context */
	const methods = useFormContext<IConstructionSiteContactForm>();
	const { colors } = useUIContext();

	/** Render */
	return (
		<VStack>
			{fieldArray.fields.length === 0 && <Text color={colors.gray}>Keine Kontakte vorhanden</Text>}
			{fieldArray.fields.map((field, index) => (
				<ContactForm
					key={field.id}
					field={field}
					index={index}
					fieldArray={fieldArray}
					isSingleEntry={isSingleEntry}
					methods={methods}
				/>
			))}
		</VStack>
	);
};

interface ContactFormProps extends ConstructionSiteContactContactsFormProps {
	field: FieldArrayWithId<ConstructionSiteContact, "contacts", "id">;
	index: number;
	isSingleEntry?: boolean;
	methods: ReturnType<typeof useFormContext<IConstructionSiteContactForm>>;
}

export const ContactForm: FC<ContactFormProps> = ({
	field,
	index,
	fieldArray,
	isSingleEntry,
	methods,
}) => {
	/** Context */
	const { colors } = useUIContext();
	const { setVisibleContacts, visibleContacts, shouldOverride } =
		useEditConstructionSiteContactModalContext();

	/** State */
	const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: index === 0 });
	const [firstName, lastName] = useWatch({
		control: methods.control,
		name: [`contacts.${index}.firstName`, `contacts.${index}.lastName`],
	});
	const fullName = `${firstName} ${lastName}`;
	const collapserLabel = fullName.trim() || "Kontakt";

	const isVisible = !shouldOverride ? true : visibleContacts.includes(field.id);

	/** Effects */
	useEffect(() => {
		if (field.id) return;
		methods.setValue(`contacts.${index}.id`, generateId());
	}, [field.id, index, methods]);

	/** Functions */
	function handleOnToggleContactVisibility() {
		if (!setVisibleContacts) return;
		if (isVisible) {
			setVisibleContacts(visibleContacts.filter((id) => id !== field.id));
		} else {
			setVisibleContacts([...visibleContacts, field.id]);
		}
	}

	/** Render */
	return (
		<VStack
			key={field.id}
			_notLast={{
				borderBottom: "1px solid",
				borderColor: colors.grayLightDarker,
				paddingBottom: 6,
			}}
		>
			{!isSingleEntry && (
				<HStack justify="space-between">
					<Collapser
						isCollapsed={!isOpen}
						onToggle={onToggle}
					>
						{index + 1}. {collapserLabel}
					</Collapser>
					{shouldOverride && (
						<IconButton
							text="In Kontaktliste anzeigen"
							icon={isVisible ? <TbEye /> : <TbEyeOff />}
							aria-label="hide contact"
							onClick={handleOnToggleContactVisibility}
							opacity={isVisible ? 1 : 0.5}
							size="xs"
						/>
					)}
				</HStack>
			)}
			<Collapse in={isOpen}>
				<VStack>
					<Grid
						gridTemplateColumns="1fr 1fr"
						gap={6}
					>
						<GridItem colSpan={2}>
							<HStack alignItems="flex-end">
								<FormControl>
									<FormLabel>Anrede</FormLabel>
									<Select
										placeholder="Bitte auswählen"
										{...methods.register(`contacts.${index}.type`)}
									>
										<option value="mr">Herr</option>
										<option value="mrs">Frau</option>
										<option value="unknown">Unbekannt</option>
									</Select>
								</FormControl>
								{!isSingleEntry && (
									<IconButton
										icon={
											<Box color={colors.red}>
												<TbTrash />
											</Box>
										}
										onClick={() => fieldArray.remove(index)}
										aria-label="Kontakt entfernen"
									/>
								)}
							</HStack>
							<FormError name={`contacts.${index}.type`} />
						</GridItem>
					</Grid>
					<Grid
						gridTemplateColumns="1fr 1fr"
						gap={6}
					>
						<FormControl>
							<FormLabel>Vorname</FormLabel>
							<Input
								placeholder="z.B.: Maximilian"
								{...methods.register(`contacts.${index}.firstName`)}
							/>
							<FormError name={`contacts.${index}.firstName`} />
						</FormControl>
						<FormControl>
							<FormLabel>Nachname</FormLabel>
							<Input
								placeholder="z.B.: Mustermann"
								{...methods.register(`contacts.${index}.lastName`)}
							/>
							<FormError name={`contacts.${index}.lastName`} />
						</FormControl>
					</Grid>
					<Switch {...methods.register(`contacts.${index}.invoiceDistributor`)}>
						Rechnungsverteiler
					</Switch>
					<FormControl>
						<FormLabel>E-Mail</FormLabel>
						<Input
							placeholder="z.B.: max@mustermann.de"
							{...methods.register(`contacts.${index}.email`)}
						/>
						<FormError name={`contacts.${index}.email`} />
					</FormControl>
					<FormControl>
						<FormLabel>Telefon</FormLabel>
						<Input
							placeholder="z.B.: +49 30 / 403 92 658"
							{...methods.register(`contacts.${index}.phone`)}
						/>
						<FormError name={`contacts.${index}.phone`} />
					</FormControl>
					<FormControl>
						<FormLabel>Mobil</FormLabel>
						<Input
							placeholder="z.B.: +49 176 20 22 63 60"
							{...methods.register(`contacts.${index}.mobile`)}
						/>
						<FormError name={`contacts.${index}.mobile`} />
					</FormControl>
					<FormControl>
						<FormLabel>Fax</FormLabel>
						<Input
							placeholder="z.B.: 030 / 403 92 65 81"
							{...methods.register(`contacts.${index}.fax`)}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>Status</FormLabel>
						<Select
							placeholder="Bitte auswählen"
							{...methods.register(`contacts.${index}.status`)}
						>
							{contactStatusArray.map((status) => (
								<option
									key={status}
									value={status}
								>
									{getContactStatusLabel(status)}
								</option>
							))}
						</Select>
						<FormError name={`contacts.${index}.status`} />
					</FormControl>
					<ContactAuthorizations index={index} />
				</VStack>
			</Collapse>
		</VStack>
	);
};
