import { createContext, useContext } from "react";

/** Interface */
interface IEditConstructionSiteContactModalContext {
	shouldOverride?: boolean;
	visibleContacts: string[];
	setVisibleContacts: (contacts: string[]) => void;
}

/** Context */
export const EditConstructionSiteContactModalContext = createContext(
	{} as IEditConstructionSiteContactModalContext,
);

/** Hook */
export const useEditConstructionSiteContactModalContext = () => {
	return useContext(EditConstructionSiteContactModalContext);
};
