import { db, storage } from "@/core";
import type {
	ConstructionSiteDocument,
	ProjectRequestTimelineFileUploadEntry,
} from "@msuite/katana";
import { HStack, IconButton, Text, VStack, toast, useDocument } from "@msuite/picasso";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { type FC, useState } from "react";
import { TbExternalLink } from "react-icons/tb";

/** Props Interface */
interface TimelineFileUploadEntryProps {
	projectRequestId: string;
	entry: ProjectRequestTimelineFileUploadEntry;
}

export const TimelineFileUploadEntry: FC<TimelineFileUploadEntryProps> = ({
	projectRequestId,
	entry: { documentId, fileName },
}) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Hooks */
	const { data, isError } = useDocument<ConstructionSiteDocument>(db, {
		path: `projectRequests/${projectRequestId}/documents/${documentId}`,
		subscribe: false,
	});

	/** Functions */
	async function handleOnOpenFile() {
		try {
			setIsLoading(true);
			const document = (
				await getDoc(doc(db, "projectRequests", projectRequestId, "documents", documentId))
			).data() as ConstructionSiteDocument;
			if (!document) throw new Error("Document not found");
			const storageRef = ref(storage, document.file_path);
			const downloadUrl = await getDownloadURL(storageRef);
			window.open(downloadUrl, "_blank");
		} catch (error) {
			console.error(error);
			toast("Fehler beim Öffnen des Dokuments");
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<HStack justifyContent="space-between">
			<VStack spacing={1}>
				<Text>
					Es wurde ein Dokument <strong>{fileName}</strong> hochgeladen.
				</Text>
				{isError && (
					<Text
						color="gray.500"
						fontSize="sm"
					>
						Das Dokument wurde durch einen Nutzer gelöscht.
					</Text>
				)}
			</VStack>
			{data && (
				<IconButton
					icon={<TbExternalLink />}
					text="Öffnen"
					aria-label="Öffnen"
					onClick={handleOnOpenFile}
					isLoading={isLoading}
				/>
			)}
		</HStack>
	);
};
