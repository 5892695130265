import { type FileNamesDocumentTree, generateId } from "@msuite/katana";
import { Box, HStack, Text, VStack, useDisclosure, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbFolderFilled } from "react-icons/tb";
import { FileNamesTreeFile } from "./file-names-tree-file";

/** Props Interface */
interface FileNamesTreeDirectoryProps {
	directory: FileNamesDocumentTree;
	isRoot?: boolean;
	basePath: string;
	currentPath?: string;
}

const NESTED_FOLDER_INDENT = 8;
const NESTED_FOLDER_SPACING = 2;

export const FileNamesTreeDirectory: FC<FileNamesTreeDirectoryProps> = ({
	directory,
	isRoot: _isRoot = false,
	basePath,
	currentPath: _currentPath,
}) => {
	const isRoot =
		_isRoot || directory.name === "." || directory.name.startsWith("root") || !directory.name;
	const currentPath = (
		!_currentPath ? directory.name : `${_currentPath}/${directory.name}`
	).replaceAll("root", "");

	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const folderState = useDisclosure({ defaultIsOpen: true });

	/** Render */
	return (
		<VStack
			pl={isRoot ? 0 : 4}
			spacing={NESTED_FOLDER_SPACING}
		>
			{isRoot ? null : (
				<HStack
					spacing={2}
					px={2}
					cursor="pointer"
					rounded="md"
					_hover={{
						bg: colors.grayDark,
					}}
					onClick={folderState.onToggle}
				>
					<Box color={colors.yellow}>
						<TbFolderFilled />
					</Box>
					<Text>{directory.name}</Text>
				</HStack>
			)}
			<VStack
				display={folderState.isOpen ? "flex" : "none"}
				paddingLeft={isRoot ? 0 : NESTED_FOLDER_INDENT}
				spacing={NESTED_FOLDER_SPACING}
			>
				{directory.files.map((file) => (
					<FileNamesTreeFile
						filePath={file.name}
						key={generateId()}
						basePath={basePath}
						currentPath={currentPath}
					/>
				))}
				{directory.directories.map((directory) => (
					<FileNamesTreeDirectory
						directory={directory}
						key={generateId()}
						basePath={basePath}
						currentPath={currentPath}
					/>
				))}
			</VStack>
		</VStack>
	);
};
