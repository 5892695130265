import {
	type ConstructionSiteContact,
	type ProjectRequest,
	getContactStatusLabel,
} from "@msuite/katana";
import {
	Grid,
	Label,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Portal,
	Text,
	Wrap,
	useUIContext,
} from "@msuite/picasso";
import type { FC } from "react";
import { IoPeople } from "react-icons/io5";

/** Props Interface */
interface ProjectRequestClientPrimaryContactProps {
	projectRequest: ProjectRequest;
	client: ConstructionSiteContact | undefined;
}

export const ProjectRequestClientPrimaryContact: FC<ProjectRequestClientPrimaryContactProps> = ({
	projectRequest,
	client,
}) => {
	/** Context */
	const { colors } = useUIContext();

	/** Props */
	const includedContacts = projectRequest.clientContacts;
	const primaryContacts = includedContacts?.filter((contact) => contact.isPrimary);

	/** Fallback */
	if (!client) return null;

	/** Render */
	return (
		<Wrap
			spacing={2}
			alignItems="center"
		>
			<Text
				pt={1.5}
				color={colors.gray}
				fontSize="sm"
			>
				<IoPeople />
			</Text>
			{primaryContacts?.map((_contact, index) => {
				const contact = client?.contacts?.find((c) => c.id === _contact.contactId);
				return (
					<Popover
						trigger="hover"
						placement="bottom-start"
						key={_contact.contactId}
						isLazy
					>
						<PopoverTrigger>
							<Text>
								{contact?.firstName} {contact?.lastName}
								{index !== primaryContacts.length - 1 ? ", " : ""}
							</Text>
						</PopoverTrigger>
						<Portal>
							<PopoverContent width="max-content">
								<PopoverBody>
									<Grid
										gridAutoRows="max-content"
										templateColumns="max-content 1fr"
										placeItems="baseline"
										columnGap={4}
										rowGap={2}
									>
										<Label>Position</Label>
										<Text>{getContactStatusLabel(contact?.status ?? "unknown") || "--"}</Text>
										<Label>E-Mail</Label>
										<Text>{contact?.email || "--"}</Text>
										<Label>Telefon</Label>
										<Text>{contact?.phone || "--"}</Text>
										<Label>Fax</Label>
										<Text>{contact?.fax || "--"}</Text>
									</Grid>
								</PopoverBody>
							</PopoverContent>
						</Portal>
					</Popover>
				);
			})}
		</Wrap>
	);
};
