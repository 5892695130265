import type { ConstructionSiteContact, EmployeeAuthorization } from "@msuite/katana";
import { constructionSiteContactTypeArray, contactStatusArray, generateId } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import * as yup from "yup";

export type IConstructionSiteContactForm = ConstructionSiteContact;

export const employeeAuthorizations: EmployeeAuthorization[] = [
	"orderAuthorization",
	"contractAcceptanceAuthorization",
	"changeOrderAuthorization",
	"hourlyWorkAuthorization",
	"performanceChangeAuthorization",
	"legalCorrespondenceAuthorization",
	"serviceRequestAuthorization",
];

const addressShape = yup.object().shape({
	street: yup.string().required("Bitte geben Sie eine Straße ein."),
	number: yup.string().required("Bitte geben Sie eine Hausnummer ein."),
	zipCode: yup.string().required("Bitte geben Sie eine Postleitzahl ein."),
	city: yup.string().required("Bitte geben Sie eine Stadt ein."),
});

const contactShape = yup.object().shape({
	id: yup.string().default(() => generateId()),
	type: yup.string().oneOf(["mr", "mrs", "unknown"]).required("Bitte wählen Sie eine Anrede aus."),
	firstName: yup.string().required("Bitte geben Sie einen Vornamen ein."),
	lastName: yup.string().required("Bitte geben Sie einen Nachnamen ein."),
	phone: yup.string().optional(),
	mobile: yup.string().optional(),
	email: yup.string().email("Bitte geben Sie eine E-Mail-Adresse ein.").optional(),
	fax: yup.string().optional(),
	status: yup.string().oneOf(contactStatusArray).required("Bitte wählen Sie einen Status aus."),
	authorizations: yup
		.array()
		.of(yup.string().oneOf(employeeAuthorizations))
		.required("Bitte wählen Sie mindestens eine Berechtigung aus."),
	invoiceDistributor: yup
		.boolean()
		.default(() => false)
		.required("Bitte wählen Sie aus, ob der Kontakt Rechnungen verteilen darf."),
});

export const contactOnlyShape = yup.object().shape({
	contacts: yup.array().of(contactShape),
});

const constructionSiteContactFormShape = (withType: boolean) => ({
	id: yup.string().default(generateId()),
	type: withType
		? yup
				.string()
				.oneOf(constructionSiteContactTypeArray)
				.required("Bitte wählen Sie aus um welchen Typ es sich handelt.")
		: yup.string().oneOf(constructionSiteContactTypeArray).default("other"),
	name: yup.string().required("Bitte geben Sie einen Namen ein."),
	address: addressShape.required("Bitte geben Sie eine Adresse ein."),
	email: yup.string().email("Bitte geben Sie eine E-Mail-Adresse ein.").optional(),
	phone: yup.string().optional(),
	mobile: yup.string().optional(),
	fax: yup.string().optional(),
	contacts: yup
		.array()
		.of(contactShape)
		.default([])
		.min(0, "Bitte fügen Sie mindestens einen Kontakt hinzu."),
	isInvoiceRecipient: yup
		.boolean()
		.default(false)
		.required("Bitte wählen Sie aus, ob der Bauherr Rechnungen erhalten soll."),
	hasAlternateInvoiceAddress: yup
		.boolean()
		.default(false)
		.required("Bitte wählen Sie aus, ob der Bauherr eine abweichende Rechnungsadresse hat."),
	alternateInvoiceAddress: addressShape.when("hasAlternateInvoiceAddress", {
		is: true,
		then: yup.object().required("Bitte geben Sie eine abweichende Rechnungsadresse ein."),
		otherwise: yup.object().strip(),
	}),
	invoiceDeliveryMethod: yup.string().when("isInvoiceRecipient", {
		is: true,
		then: yup.string().oneOf(["email", "mail", "other"]).required(),
		otherwise: yup.string().strip(),
	}),
	alternateInvoiceDeliveryMethod: yup.string().when("invoiceDeliveryMethod", {
		is: "other",
		then: yup.string().required("Bitte geben Sie eine abweichende Rechnungszustellmethode ein."),
		otherwise: yup.string().strip(),
	}),
	alternateEmailForInvoice: yup
		.string()
		.email("Bitte geben Sie eine E-Mail-Adresse ein.")
		.optional(),
	alwaysIncludeEmailInInvoice: yup
		.boolean()
		.default(false)
		.required("Bitte wählen Sie aus, ob die E-Mail-Adresse immer auf der Rechnung stehen soll."),
});

const constructionSiteContactFormShapeLight = (withType: boolean) => ({
	type: constructionSiteContactFormShape(withType).type,
	name: constructionSiteContactFormShape(withType).name,
});

export const constructionSiteContactFormSchema = (withType: boolean) =>
	yup.object().shape(constructionSiteContactFormShape(withType));
export const constructionSiteContactFormSchemaLight = (withType: boolean) =>
	yup.object().shape(constructionSiteContactFormShapeLight(withType));
export const FormError = FormErrorMessage<IConstructionSiteContactForm>;
