import { Center, Heading, Text, VStack } from "@msuite/picasso";
import { AnimatePresence, motion } from "framer-motion";
import { type FC, type RefObject, useEffect, useRef, useState } from "react";
import { IoLogoAppleAr } from "react-icons/io5";

/** Props Interface */
interface LoadingScreenProps {
	retryAction?: () => void;
	minH?: string;
	subtext?: string;
}

export const LoadingScreen: FC<LoadingScreenProps> = ({ retryAction, minH = "65rem", subtext }) => {
	/** State */
	const [showError, setShowError] = useState<boolean>(false);

	/** Hooks */
	const containerRef = useRef() as RefObject<HTMLDivElement>;

	/** Effects */
	useEffect(() => {
		if (!showError) setTimeout(() => setShowError(true), 7500);
	}, [showError]);

	function retry() {
		if (retryAction) retryAction();
		setShowError(false);
	}

	/** Render */
	return (
		<AnimatePresence mode="wait">
			<Center
				ref={containerRef}
				w="100%"
				h="100%"
				overflow="hidden"
				minH={minH}
			>
				<VStack
					spacing={6}
					mt="2rem"
					alignItems="center"
				>
					<motion.div
						animate={{
							rotate: [0, 360, 0],
						}}
						transition={{
							duration: 1.5,
							repeat: Number.POSITIVE_INFINITY,
							ease: "easeInOut",
						}}
					>
						<Text
							fontSize="2xl"
							cursor="pointer"
							color="gray.600"
						>
							<IoLogoAppleAr size={26} />
						</Text>
					</motion.div>

					<Heading
						size="md"
						textAlign="center"
					>
						Daten werden geladen
					</Heading>

					{!showError ? (
						subtext && (
							<Heading
								fontSize="xs"
								fontWeight="medium"
								color="gray.500"
								transition=".35s ease-in-out"
								textAlign="center"
							>
								{subtext}
							</Heading>
						)
					) : (
						<VStack
							alignItems="center"
							spacing={1}
							pointerEvents={showError ? "all" : "none"}
						>
							<Heading
								fontSize="xs"
								fontWeight="medium"
								color="gray.500"
								opacity={showError ? 1 : 0}
								transform={showError ? "translateY(0px)" : "translateY(20px)"}
								transition=".35s ease-in-out"
							>
								Dieser Vorgang dauert länger als erwartet.
							</Heading>
							{retryAction && (
								<Heading
									opacity={showError ? 1 : 0}
									transform={showError ? "translateY(0px)" : "translateY(20px)"}
									fontSize="xs"
									fontWeight="medium"
									color="twitter.500"
									cursor="pointer"
									_active={{ opacity: 0.8 }}
									onClick={retry}
									transition=".55s ease-in-out"
								>
									{" "}
									Erneut versuchen
								</Heading>
							)}
						</VStack>
					)}
				</VStack>
			</Center>
		</AnimatePresence>
	);
};
