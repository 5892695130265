import { db } from "@/core";
import { ConstructionSiteContactForm } from "@/forms/construction-site-contact-form";
import { ConstructionSiteContactContactsForm } from "@/forms/construction-site-contact-form/construction-site-contact-contacts-form";
import { constructionSiteContactFormSchema } from "@/forms/construction-site-contact-form/schema";
import { EditConstructionSiteContactModalContext } from "@/modals/construction-sites/edit-construction-site-contact-modal/context";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	type ConstructionSiteContact,
	type ConstructionSiteContactOverride,
	findDifferences,
	generateId,
} from "@msuite/katana";
import {
	Button,
	HStack,
	IconButton,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	ModalTitle,
	SegmentedControl,
	VStack,
	findFirstErrorMessage,
	toast,
} from "@msuite/picasso";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { type FC, useState } from "react";
import { FormProvider, useFieldArray, useForm, type useFormState } from "react-hook-form";
import { TbPlus } from "react-icons/tb";
import { UnlinkContactButton } from "./unlink-contact-button";

/** Props Interface */
interface EditConstructionSiteContactModalPropsBase {
	isOpen: boolean;
	onClose: () => void;
	contact: ConstructionSiteContact & { id: string };
	onComplete?: () => void;
}

interface EditConstructionSiteContactModalPropsWithoutOverride
	extends EditConstructionSiteContactModalPropsBase {
	shouldOverride?: false;
	constructionSiteId?: undefined;
	currentOverride?: undefined;
}

interface EditConstructionSiteContactModalPropsWithOverride
	extends EditConstructionSiteContactModalPropsBase {
	shouldOverride: true;
	constructionSiteId: string | undefined;
	currentOverride: ConstructionSiteContactOverride | undefined;
}

type EditConstructionSiteContactModalProps =
	| EditConstructionSiteContactModalPropsWithoutOverride
	| EditConstructionSiteContactModalPropsWithOverride;

export async function handleOverride(
	data: ConstructionSiteContact,
	contactId: string,
	constructionSiteId: string,
	visibleContacts: string[],
) {
	try {
		const originalContactRef = doc(db, `_contactBook/${contactId}`);
		const originalContact = (await getDoc(originalContactRef)).data() as ConstructionSiteContact;
		const newContacts = data.contacts;
		await updateDoc(originalContactRef, { contacts: newContacts });
		originalContact.contacts = undefined;
		data.contacts = undefined;
		const differences = findDifferences(originalContact, data);
		const contactOverrideRef = doc(
			db,
			`baustellen/${constructionSiteId}/contactOverrides/${contactId}`,
		);
		const override: ConstructionSiteContactOverride = {
			id: contactId,
			override: differences,
			visibleContacts,
		};
		await setDoc(contactOverrideRef, override);
	} catch (error) {
		console.error(error);
	}
}

export const EditConstructionSiteContactModal: FC<EditConstructionSiteContactModalProps> = ({
	isOpen,
	onClose,
	contact,
	shouldOverride,
	constructionSiteId,
	currentOverride,
}) => {
	/** State */
	const [currentView, setCurrentView] = useState<"company" | "contacts">("company");
	const [visibleContacts, setVisibleContacts] = useState<string[]>(
		currentOverride?.visibleContacts || [],
	);

	/** Hooks */
	const methods = useForm<ConstructionSiteContact>({
		defaultValues: contact,
		resolver: yupResolver(constructionSiteContactFormSchema(shouldOverride === true) as any) as any,
	});
	const contactsArray = useFieldArray({
		control: methods.control,
		name: "contacts",
		keyName: "_id",
	});

	/** Functions */
	async function onSubmit(data: ConstructionSiteContact) {
		try {
			if (shouldOverride) {
				if (!constructionSiteId) throw new Error("constructionSiteId is required");
				await handleOverride(data, contact.id, constructionSiteId, visibleContacts);
			} else {
				const contactRef = doc(db, `_contactBook/${contact.id}`);
				await setDoc(contactRef, data);
			}
			onClose();
			toast.success("Baustellenkontakt wurde erfolgreich aktualisiert");
		} catch (error) {
			toast.error("Es ist ein Fehler aufgetreten.");
			console.error(error);
		}
	}

	function handleAddContact() {
		const id = generateId();
		setVisibleContacts([...visibleContacts, id]);
		contactsArray.append({
			id,
			type: "mr",
			firstName: "",
			lastName: "",
			phone: "",
			email: "",
			status: "unknown",
			authorizations: [],
			invoiceDistributor: false,
		});
	}

	async function onReject(e: ReturnType<typeof useFormState>["errors"]) {
		const firstErrorMessage = findFirstErrorMessage(e);
		toast.error(firstErrorMessage);
	}

	/** Render */
	return (
		<EditConstructionSiteContactModalContext.Provider
			value={{
				shouldOverride,
				visibleContacts,
				setVisibleContacts,
			}}
		>
			<FormProvider {...methods}>
				<Modal
					isOpen={isOpen}
					onClose={onClose}
				>
					<ModalOverlay />
					<form onSubmit={methods.handleSubmit(onSubmit, onReject)}>
						<ModalContent>
							<ModalCloseButton />
							<ModalHeader>
								<VStack>
									<ModalTitle>{contact.name} bearbeiten</ModalTitle>
									<HStack justify="space-between">
										<SegmentedControl
											value={currentView}
											onChange={setCurrentView}
											options={[
												{ label: "Unternhemen", value: "company" },
												{ label: "Kontakte", value: "contacts" },
											]}
										/>
										{currentView === "contacts" && (
											<IconButton
												size="xs"
												icon={<TbPlus />}
												onClick={handleAddContact}
												aria-label="add"
											/>
										)}
									</HStack>
								</VStack>
							</ModalHeader>
							<ModalBody>
								{currentView === "company" && <ConstructionSiteContactForm key="company-form" />}
								{currentView === "contacts" && (
									<ConstructionSiteContactContactsForm
										key="contacts-form"
										fieldArray={contactsArray}
									/>
								)}
							</ModalBody>
							<ModalFooter>
								<HStack
									justifyContent="space-between"
									width="100%"
								>
									<UnlinkContactButton
										constructionSiteId={constructionSiteId}
										contactId={contact?.id}
										onComplete={onClose}
									/>
									<ModalButtonGroup>
										<Button
											variant="ghost"
											onClick={onClose}
										>
											abbrechen
										</Button>
										<Button
											isLoading={methods.formState.isSubmitting}
											type="submit"
										>
											Aktualisieren
										</Button>
									</ModalButtonGroup>
								</HStack>
							</ModalFooter>
						</ModalContent>
					</form>
				</Modal>
			</FormProvider>
		</EditConstructionSiteContactModalContext.Provider>
	);
};
