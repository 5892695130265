import { storage } from "@/core";
import { Icon } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import { HStack, IconButton, Spacer, Text, toast } from "@msuite/picasso";
import { getDownloadURL, ref } from "firebase/storage";
import { type FC, useState } from "react";
import { TbFile } from "react-icons/tb";

/** Props Interface */
interface FileNamesTreeFileProps {
	filePath: string;
	basePath: string;
	currentPath: string;
}

export const FileNamesTreeFile: FC<FileNamesTreeFileProps> = ({
	filePath,
	basePath,
	currentPath,
}) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Constants */
	const fileName = filePath.split("/").pop();
	const extension = fileName?.split(".").pop();
	const fullPath = `${basePath}/${currentPath}/${filePath}`.replaceAll("//", "/");

	/** Functions */
	async function handleOnOpenFile() {
		try {
			setIsLoading(true);
			const docRef = ref(storage, fullPath);
			toast(fullPath);
			const downloadUrl = await getDownloadURL(docRef);
			window.open(downloadUrl, "_blank");
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<HStack spacing={2}>
			<Icon {...getFileTypeIconProps({ extension })} />
			<Text
				noOfLines={1}
				textOverflow="ellipsis"
				mr={8}
			>
				{fileName}
			</Text>
			<Spacer />
			<IconButton
				size="xs"
				text="Öffnen"
				aria-label="Öffnen"
				icon={<TbFile />}
				onClick={handleOnOpenFile}
				isLoading={isLoading}
			/>
		</HStack>
	);
};
