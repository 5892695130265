import { Center, GridItem, Text, VStack, useDropzone, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbFileFilled, TbFolderFilled } from "react-icons/tb";

/** Props Interface */
interface DropareaClickProps {
	handleOnFilesChange: (files: File[]) => void;
	isFolderPicker?: boolean;
}

export const DropareaClick: FC<DropareaClickProps> = ({ handleOnFilesChange, isFolderPicker }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: handleOnFilesChange,
	});

	/** Render */
	return (
		<GridItem>
			<Center
				{...getRootProps()}
				paddingY={5}
				borderWidth={1}
				borderStyle="dashed"
				rounded="xl"
				backgroundColor={colors.whiteAdjusted}
				color={colors.gray}
				cursor="pointer"
				_hover={{
					color: colors.brand,
					borderColor: colors.brand,
				}}
			>
				<VStack
					alignItems="center"
					spacing={2}
				>
					{isFolderPicker ? <TbFolderFilled /> : <TbFileFilled />}
					<Text
						fontSize="sm"
						color={colors.gray}
					>
						{isFolderPicker ? "Ordner auswählen" : "Dateien auswählen"}
					</Text>
				</VStack>
			</Center>
			<input
				{...getInputProps()}
				multiple
				// @ts-ignore
				webkitdirectory={isFolderPicker ? "" : undefined}
				style={{
					visibility: "hidden",
				}}
			/>
		</GridItem>
	);
};
