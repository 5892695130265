import { Center, Heading, VStack } from "@msuite/picasso";
import type { FC } from "react";
import { IoHelp } from "react-icons/io5";

/** Props Interface */
interface BlankSlateProps {
	heading?: string;
	subtext?: string;
	minH?: string;
	icon?: React.ReactElement;
	withBorder?: boolean;
}

export const BlankSlate: FC<BlankSlateProps> = ({ heading, subtext, minH, icon, withBorder }) => {
	/** Render */
	return (
		<Center
			userSelect="none"
			w="100%"
			h="100%"
			overflow="hidden"
			py="3rem"
			minH={minH}
			borderWidth={withBorder ? 1 : undefined}
			borderStyle="dashed"
			rounded="lg"
		>
			<VStack
				spacing={3}
				alignItems="center"
			>
				<Heading
					size="lg"
					cursor="pointer"
					color="gray.600"
				>
					{icon}
				</Heading>

				<Heading
					size="sm"
					textAlign="center"
				>
					{heading}
				</Heading>

				<Heading
					size="xs"
					fontWeight="medium"
					color="gray.500"
					textAlign="center"
				>
					{subtext}
				</Heading>
			</VStack>
		</Center>
	);
};

/** Default Props */
BlankSlate.defaultProps = {
	heading: "Keine Daten vorhanden",
	subtext: "Es konnten keine Daten gefunden werden",
	minH: "31rem",
	icon: <IoHelp size={26} />,
};
