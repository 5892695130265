import { ax, db } from "@/core";
import { ProjectRequestForm } from "@/forms/project-request-form";
import {
	type ProjectRequestFormType,
	projectRequestFormSchema,
} from "@/forms/project-request-form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import type { ProjectRequest } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@msuite/picasso";
import { doc, getDoc } from "firebase/firestore";
import { type FC, Fragment, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface EditProjectRequestModalProps {
	isOpen: boolean;
	onClose: () => void;
	projectRequestId: string;
}

export const EditProjectRequestModal: FC<EditProjectRequestModalProps> = ({
	isOpen,
	onClose,
	projectRequestId,
}) => {
	/** State */
	const [hasResponseClientId, setHasResponseClientId] = useState<boolean>(false);

	/** Hooks */
	const methods = useForm<ProjectRequestFormType>({
		defaultValues: async () => {
			const projectRequestRef = doc(db, `projectRequests/${projectRequestId}`);
			const projectRequest = (await getDoc(projectRequestRef)).data() as ProjectRequest;
			if (projectRequest.responseClientId) setHasResponseClientId(true);
			return projectRequest;
		},
		resolver: zodResolver(projectRequestFormSchema),
	});

	/** Functions */
	function handleOnClose() {
		methods.reset();
		onClose();
	}

	async function handleOnSubmit(values: ProjectRequestFormType) {
		try {
			await ax.patch(
				`/v2/project-requests/${projectRequestId}?hasResponseClientId=${hasResponseClientId}`,
				{
					data: { values },
				},
			);
			handleOnClose();
		} catch (error) {
			console.error(error);
		}
	}

	/** Render */
	return (
		<Fragment>
			<FormProvider {...methods}>
				<Modal
					isOpen={isOpen}
					onClose={handleOnClose}
				>
					<ModalOverlay />
					<form onSubmit={methods.handleSubmit(handleOnSubmit)}>
						<ModalContent>
							<ModalCloseButton />
							<ModalHeader>Anfrage bearbeiten</ModalHeader>
							<ModalBody>
								{!methods.formState.isLoading && (
									<ProjectRequestForm
										hasResponseClientId={hasResponseClientId}
										setHasResponseClientId={setHasResponseClientId}
									/>
								)}
							</ModalBody>
							<ModalFooter>
								<ModalButtonGroup>
									<Button
										variant="ghost"
										onClick={handleOnClose}
									>
										abbrechen
									</Button>
									<Button
										type="submit"
										isLoading={methods.formState.isSubmitting}
									>
										Aktualisieren
									</Button>
								</ModalButtonGroup>
							</ModalFooter>
						</ModalContent>
					</form>
				</Modal>
			</FormProvider>
		</Fragment>
	);
};
