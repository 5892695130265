import { BlankSlate } from "@/components/blank-slate";
import { SurveyContent } from "@/components/survey";
import { HandleSurveyModalContext } from "@/modals/surveys/handle-survey-modal/context";
import { type FC, useContext } from "react";

export const Content: FC = () => {
	/** Context */
	const { selectedSurvey, setSelectedSurvey } = useContext(HandleSurveyModalContext);

	/** Functions */
	const onCancel = () => {
		setSelectedSurvey(undefined);
	};

	/** Render */
	return selectedSurvey ? (
		<SurveyContent
			survey={selectedSurvey}
			onCancel={onCancel}
		/>
	) : (
		<BlankSlate />
	);
};
