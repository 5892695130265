import type { ConstructionSite } from "@msuite/katana";
import type { AutoCompleteRenderItemProps } from "@msuite/picasso";
import { Text, VStack } from "@msuite/picasso";

export const AutoCompleteItem: AutoCompleteRenderItemProps<ConstructionSite> = ({ option }) => {
	return (
		<VStack
			spacing={0}
			alignItems="flex-start"
			width="100%"
			style={{
				contentVisibility: "auto",
			}}
		>
			<Text fontSize="sm">{option.item?.nummer}</Text>
			<Text fontWeight="medium">{option.item?.bauvorhaben}</Text>
		</VStack>
	);
};
