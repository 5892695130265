import { SurveyContext } from "@/components/survey/context";
import { QuestionContext } from "@/components/survey/survey-display/question/context";
import { VStack } from "@msuite/picasso";
import { type FC, useContext } from "react";

/** Props Interface */
export interface QuestionWrapperProps {
	children?: React.ReactNode;
}

export const QuestionWrapper: FC<QuestionWrapperProps> = ({ children }) => {
	/** Context */
	const { survey, activeElement } = useContext(SurveyContext);

	/** Get Active Question */
	const question = survey?.sections
		.find(
			(section) =>
				section.id === activeElement?.sectionId && activeElement.repeatKey === section.repeat_key,
		)
		?.questions.find((question) => question.id === activeElement?.questionId);

	/** Render */
	return (
		<QuestionContext.Provider value={{ question }}>
			<VStack
				position="absolute"
				top="0"
				left="0"
				right="0"
				bottom="0"
				px="10vw"
				py="10vh"
				align="stretch"
				spacing={6}
			>
				{children}
			</VStack>
		</QuestionContext.Provider>
	);
};
