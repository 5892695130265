import { db } from "@/core";
import { AddConstructionSiteModal } from "@/modals/construction-sites/add-construction-site-modal";
import { PickConstructionSiteModal } from "@/modals/construction-sites/pick-construction-site-modal";
import { EditOfferModal } from "@/modals/my-space/edit-offer-modal";
import type {
	ConstructionSite,
	ConstructionSiteContractDocument,
	Employee,
	Offer,
} from "@msuite/katana";
import { generateId, moment } from "@msuite/katana";
import {
	Box,
	Button,
	Grid,
	HStack,
	IconButton,
	Label,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
	toast,
	useAuthContext,
	useDisclosure,
	useDocument,
} from "@msuite/picasso";
import { FileCardUploaded } from "components/card";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { type FC, Fragment, useMemo } from "react";
import { IoPencil } from "react-icons/io5";

/** Props Interface */
interface OfferDetailsModalProps {
	isOpen: boolean;
	onClose: () => void;
	offerId: string;
	withEdit?: boolean;
}

export const OfferDetailsModal: FC<OfferDetailsModalProps> = ({
	isOpen,
	onClose,
	offerId,
	withEdit = true,
}) => {
	/** Context */
	const { userId } = useAuthContext();

	/** Hooks */
	const editOfferModal = useDisclosure();
	const addConstructionSiteModal = useDisclosure();
	const pickConstructionSiteModal = useDisclosure();
	const { data: offer } = useDocument<Offer>(db, {
		path: `offers/${offerId}`,
		subscribe: true,
	});
	const { data: employee } = useDocument<Employee>(db, {
		path: `mitarbeiter/${offer?.employeeId}`,
		subscribe: false,
		queryClientOptions: {
			enabled: !!offer?.employeeId,
		},
	});

	const initialValues = useMemo(() => {
		const init: Partial<ConstructionSite> = {
			auftraggeber: offer?.customerName,
			bauvorhaben: offer?.projectName,
			strasse: offer?.projectStreet,
			hausnummer: offer?.projectHouseNumber,
			plz: offer?.projectZipCode,
			ort: offer?.projectCity,
			bauleiter: [offer?.employeeId ?? ""].filter(Boolean),
		};
		return init;
	}, [offer]);

	/** Functions */
	async function handleOnComplete(constructionSite: ConstructionSite | null) {
		if (constructionSite?.id) await handleOnLinkOffer(constructionSite.id, "created");
	}

	async function handleOnPickedConstructionSite(constructionSiteId: string) {
		await handleOnLinkOffer(constructionSiteId, "linked");
	}

	async function handleOnLinkOffer(constructionSiteId: string, type: "linked" | "created") {
		try {
			if (!offer) throw new Error("Offer not found");
			const offerRef = doc(db, "offers", offer.id);
			if (!constructionSiteId) throw new Error("Construction site not found");
			await updateDoc(offerRef, { constructionSiteId });
			const constructionSiteRef = doc(db, "baustellen", constructionSiteId);
			const contractDocumentsRef = collection(constructionSiteRef, "contract_documents");
			const fileNames = [`${offer.id}.pdf`, ...(offer.files ?? [])];
			let isInitialOffer = true;
			for (const fileName of fileNames) {
				const documentName = fileName.split(".")[0];
				const collectionRef = contractDocumentsRef;
				const document: ConstructionSiteContractDocument = {
					id: generateId(),
					file_path: `offers/${fileName}`,
					file_name: isInitialOffer ? "Angebot" : documentName,
					created_by: userId ?? "",
					created_at: moment().toISOString(),
					isExposed: false,
					is_directory: false,
				};
				const documentRef = doc(collectionRef, document.id);
				await setDoc(documentRef, document);
				if (isInitialOffer) isInitialOffer = false;
			}
			onClose();
			const toastLabel =
				type === "linked"
					? "Dokumente zum Bauvorhaben hinzugefügt"
					: "Bauvorhaben erstellt und Dokumente hinzugefügt";
			toast.success(toastLabel);
		} catch (error) {
			console.error(error);
		}
	}

	/** Render */
	return (
		<Fragment>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				blockScrollOnMount={false}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalHeader>Angebot: {offer?.projectName}</ModalHeader>
					<ModalBody>
						<VStack>
							<HStack justify="space-between">
								<VStack spacing={0}>
									<Label>Angebotsdatum</Label>
									<Text>{moment(offer?.offerDate).format("L")}</Text>
								</VStack>
								{withEdit && (
									<IconButton
										size="xs"
										text="Bearbeiten"
										icon={<IoPencil />}
										aria-label="Bearbeiten"
										onClick={editOfferModal.onOpen}
									/>
								)}
							</HStack>
							<VStack spacing={0}>
								<Label>Angebotsnummer</Label>
								<Text>{offer?.offerNumber}</Text>
							</VStack>
							<VStack spacing={0}>
								<Label>Erstellt von</Label>
								<Text>
									{employee ? `${employee.vorname} ${employee.nachname}` : "Nicht erkannt"}
								</Text>
							</VStack>
							<VStack spacing={0}>
								<Label>Projekt</Label>
								<Text>{offer?.projectName}</Text>
							</VStack>
							<VStack spacing={0}>
								<Label>Firma</Label>
								<Text>{offer?.companyName}</Text>
							</VStack>
							<VStack spacing={0}>
								<Label>Auftraggeber</Label>
								<Text>{offer?.customerName}</Text>
							</VStack>
							<VStack spacing={0}>
								<Label>Angebot</Label>
								<Grid
									gap={4}
									templateColumns="repeat(auto-fill, minmax(10rem, 1fr))"
								>
									<Box width="100%">
										<FileCardUploaded
											fileName={`${offer?.offerId}.pdf`}
											path="offers"
											withoutDelete
										/>
									</Box>
								</Grid>
							</VStack>
							<VStack
								spacing={0}
								width="100%"
							>
								<Label>Weitere Dokumente</Label>
								<Grid
									gap={4}
									templateColumns="repeat(auto-fill, minmax(10rem, 1fr))"
								>
									{offer?.files?.length ? (
										<Fragment>
											{offer?.files?.map((file) => (
												<Box
													width="100%"
													style={{ aspectRatio: "1" }}
													key={file}
												>
													<FileCardUploaded
														fileName={file}
														path="offers"
														withoutDelete
													/>
												</Box>
											))}
										</Fragment>
									) : (
										<Text>Keine weiteren Dokumente</Text>
									)}
								</Grid>
							</VStack>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<ModalButtonGroup>
							{!offer?.constructionSiteId && withEdit && (
								<Fragment>
									<Button onClick={pickConstructionSiteModal.onOpen}>
										Zu Bauvorhaben hinzufügen
									</Button>
									<Button onClick={addConstructionSiteModal.onOpen}>Bauvorhaben erstellen</Button>
								</Fragment>
							)}
							<Button onClick={onClose}>Fertig</Button>
						</ModalButtonGroup>
					</ModalFooter>
					{offer && (
						<EditOfferModal
							key={offer.updatedAt}
							isOpen={editOfferModal.isOpen}
							onClose={editOfferModal.onClose}
							offer={offer}
						/>
					)}
					{isOpen && (
						<PickConstructionSiteModal
							isOpen={pickConstructionSiteModal.isOpen}
							onClose={pickConstructionSiteModal.onClose}
							onComplete={handleOnPickedConstructionSite}
						/>
					)}
					{isOpen && initialValues && (
						<AddConstructionSiteModal
							key={JSON.stringify(initialValues)}
							isOpen={addConstructionSiteModal.isOpen}
							onClose={addConstructionSiteModal.onClose}
							withExistingSearchBox={false}
							initialValues={initialValues}
							onComplete={handleOnComplete}
						/>
					)}
				</ModalContent>
			</Modal>
		</Fragment>
	);
};
