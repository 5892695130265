import { useConstructionSiteCardContext } from "@/components/card/construction-site-card/context";
import { db } from "@/core";
import { usePlanContext } from "@/sites/plan/context";
import { getDailyReferenceString } from "@/sites/plan/dailies/daily/use-daily";
import type { Dayinfo, Moment } from "@msuite/katana";
import { useUIContext } from "@msuite/picasso";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import type { FC } from "react";
import { useTicketCardContext, useTicketsContext } from "../../context";
import { ActionBarButton } from "../action-bar-button";

export async function handleOnNotAssign({
	ticketIds,
	activeDailyInfo,
	weeklyReference,
	activeDate,
	forceNa = false,
}: {
	ticketIds: string[];
	activeDailyInfo: Dayinfo | undefined;
	weeklyReference: string;
	activeDate: Moment;
	forceNa?: boolean;
}) {
	let na = activeDailyInfo?.na ?? [];
	for (const ticketId of ticketIds) {
		if (forceNa) {
			na.push(ticketId);
		} else {
			if (na.includes(ticketId)) na = na.filter((id) => id !== ticketId);
			else na.push(ticketId);
		}
	}
	const dailyReference = getDailyReferenceString?.(weeklyReference, activeDate);
	const dailyRef = doc(db, dailyReference);
	await updateDoc(dailyRef, { na }).catch(() => {
		setDoc(dailyRef, { na, exists: true });
	});
}

export const NotAssignButton: FC = () => {
	/** Context */
	const { colors } = useUIContext();
	const { activeDate, weeklyReference, activeDailyInfo, activeIsBlocked } = usePlanContext();
	const { withMultiSelect } = useConstructionSiteCardContext();
	const { ticket } = useTicketCardContext();
	const { selectedTicketIndices, setSelectedTicketIndices, filteredTickets } = useTicketsContext();

	/** Functions */
	async function handleMultiSelect() {
		if (withMultiSelect && selectedTicketIndices?.length > 0) {
			const ticketIds = filteredTickets
				.map((ticket) => ticket.id)
				.filter((_, index) => selectedTicketIndices.includes(index));
			await handleOnNotAssign({
				ticketIds,
				activeDailyInfo,
				weeklyReference,
				activeDate,
			});
		} else {
			await handleOnNotAssign({
				ticketIds: [ticket.id],
				activeDailyInfo,
				weeklyReference,
				activeDate,
			});
		}
		setSelectedTicketIndices([]);
	}

	/** Render */
	return (
		<ActionBarButton
			color={colors.yellow}
			onClick={handleMultiSelect}
			isDisabled={activeIsBlocked}
		/>
	);
};
