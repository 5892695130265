import { useConstructionSiteCardContext } from "@/components/card/construction-site-card/context";
import { TicketsContext } from "@/components/card/ticket-card/context";
import { type DataDraggablePayload, createDataPayload } from "@/sites/plan/handlers";
import {
	DraggableWrapper,
	type DropResult,
	Droppable,
	type DroppableProvided,
	VStack,
} from "@msuite/picasso";
import {
	forwardRef,
	useCallback,
	useEffect,
	useId,
	useImperativeHandle,
	useMemo,
	useState,
} from "react";
import { TicketCard } from "../../ticket-card";
import { NoTicketsAvailable } from "../no-tickets-available";

/** Props Interface */
export const Tickets = forwardRef((_, ref) => {
	/** State */
	const [selectedTicketIndices, setSelectedTicketIndices] = useState<number[]>([]);

	/** Context */
	const {
		constructionSite,
		constructionSiteId,
		ticketFilterFn,
		generateTicketDraggableId,
		disableTicketDrag,
		ticketsDroppableType,
		onTicketDrop,
		ignoreInactiveTickets,
		showAllActiveTickets,
	} = useConstructionSiteCardContext();

	/** Memoized */
	const filteredTickets = useMemo(getFilteredTickets, [
		constructionSite,
		JSON.stringify(constructionSite?.tickets),
		ticketFilterFn,
		ignoreInactiveTickets,
		showAllActiveTickets,
	]);

	const selectedTicketIds = useMemo(() => {
		return filteredTickets
			.filter((_, index) => selectedTicketIndices.includes(index))
			.map((ticket) => ticket.id);
	}, [selectedTicketIndices, filteredTickets]);

	const renderId = useId();

	/** Functions */
	function getFilteredTickets() {
		const tickets = constructionSite?.tickets ?? [];
		if (showAllActiveTickets) return tickets;
		const ticketFiltered = tickets.filter((ticket) => ticketFilterFn?.(ticket) ?? true) ?? [];
		if (ticketFiltered?.length) return ticketFiltered;
		if (ignoreInactiveTickets) return [];
		const ticketsInactive = constructionSite?.inactive ?? [];
		const ticketsInactiveFiltered =
			ticketsInactive.filter((ticket) => ticketFilterFn?.(ticket) ?? true) ?? [];
		if (ticketsInactiveFiltered?.length) return ticketsInactiveFiltered;
		return [];
	}

	function handleOnDropCallback(dataPayload: DataDraggablePayload, _: DropResult) {
		try {
			if (dataPayload.type !== "ticket") return;
			if (!onTicketDrop) return;
			if (!constructionSiteId) return;
			const ticketId = dataPayload.payload.ticketId;
			setSelectedTicketIndices([]);
			if (selectedTicketIds?.length) onTicketDrop(selectedTicketIds, constructionSiteId);
			else onTicketDrop(ticketId, constructionSiteId);
		} catch (error) {
			console.error(error);
		}
	}

	const selectAllTickets = useCallback(() => {
		setSelectedTicketIndices(filteredTickets.map((_, index) => index));
	}, [filteredTickets]);

	/** Imperative Handle */
	useImperativeHandle(
		ref,
		() => ({
			selectAllTickets,
		}),
		[selectAllTickets],
	);

	/** Effects */
	useEffect(() => {
		setSelectedTicketIndices([]);
	}, [filteredTickets.length]);

	/** Render */
	return (
		<TicketsContext.Provider
			value={{
				selectedTicketIndices,
				setSelectedTicketIndices,
				filteredTickets,
			}}
		>
			<Droppable
				droppableId={`droppable-${renderId}`}
				type={ticketsDroppableType}
				isDropDisabled
			>
				{(provided: DroppableProvided) => (
					<VStack
						ref={provided.innerRef}
						{...provided.droppableProps}
						alignItems="stretch"
						spacing={0}
						pt={1}
					>
						{!filteredTickets?.length && <NoTicketsAvailable />}
						{filteredTickets.map((ticket, index) => {
							const draggableId = generateTicketDraggableId?.(ticket, index) ?? ticket.id;
							return (
								<DraggableWrapper
									key={ticket.id}
									draggableProps={{
										draggableId,
										index,
									}}
									isDisabled={disableTicketDrag}
									dataPayload={createDataPayload<DataDraggablePayload>(
										"ticket",
										{
											ticketId: ticket.id,
											constructionSiteId: constructionSite?.id,
											ticketIdArray: selectedTicketIds,
										},
										onTicketDrop !== undefined,
									)}
									onCallback={handleOnDropCallback}
									multiDragIndicator={selectedTicketIndices.length}
								>
									<TicketCard
										ticket={ticket}
										index={index}
										key={ticket.id}
										ticketIndex={index}
									/>
								</DraggableWrapper>
							);
						})}
						{provided.placeholder}
					</VStack>
				)}
			</Droppable>
		</TicketsContext.Provider>
	);
});

Tickets.displayName = "Tickets";
