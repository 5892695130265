import { useHandleRentListModalContext } from "@/modals/misc/handle-rent-list-modal/context";
import { searchify } from "@msuite/katana";
import { ModalBody, VStack } from "@msuite/picasso";
import { type FC, useMemo } from "react";
import { EmptyNote } from "./empty-note";
import { ResponseCard } from "./response-card";

export const HandleRentListModalBody: FC = () => {
	/** Context */
	const { rentList, currentState, globalFilter } = useHandleRentListModalContext();

	/** Memoized */
	const filteredRentList = useMemo(() => {
		const sortedRentList = [...rentList]?.sort((a, b) => {
			return a.constructionSite.nummer?.localeCompare(b.constructionSite.nummer ?? "") ?? 0;
		});
		if (currentState === "all") return sortedRentList;
		return sortedRentList?.filter((item) => item.answer === currentState);
	}, [rentList, currentState]);

	const globalFilteredRentList = useMemo(() => {
		return searchify(filteredRentList, globalFilter);
	}, [filteredRentList, globalFilter]);

	/** Render */
	return (
		<ModalBody>
			<VStack spacing={4}>
				{globalFilteredRentList.length === 0 ? (
					<EmptyNote />
				) : (
					<>
						{globalFilteredRentList?.map((item) => (
							<ResponseCard
								item={item}
								key={item.constructionSiteId}
							/>
						))}
					</>
				)}
			</VStack>
		</ModalBody>
	);
};
