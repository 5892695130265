import { FormErrorMessage } from "@msuite/picasso";
import { z } from "zod";

export const pickConstructionSiteSchema = z.object({
	constructionSiteId: z.string().min(1),
});

export type PickConstructionSiteFormType = z.infer<typeof pickConstructionSiteSchema>;

export const FormError = FormErrorMessage<PickConstructionSiteFormType>;
