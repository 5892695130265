import { SurveyContext } from "@/components/survey/context";
import { QuestionContext } from "@/components/survey/survey-display/question/context";
import { ax } from "@/core";
import type { ISurveyResponseEntry } from "@msuite/katana";
import { Button, Spacer, VStack, useAuthContext, useColorModeValue } from "@msuite/picasso";
import { type FC, useContext } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Description } from "../description";
import { Prompt } from "../prompt";
import { QuestionStepper } from "../question-stepper";
import { RequiredIndicator } from "../required-indicator";
import { MultipleChoicesPossible } from "./multiple-choices-possible";

export const MultipleChoiceQuestion: FC = () => {
	/** Context */
	const { user } = useAuthContext();
	const { getCurrentResponse, activeElement, survey, workspaceId, response, surveyPath } =
		useContext(SurveyContext);
	const { question } = useContext(QuestionContext);

	/** @msuite/picasso */
	const { getValues, setValue, control } = useForm<ISurveyResponseEntry>({
		defaultValues: getCurrentResponse(question) || {
			questionId: question?.id,
			sectionId: activeElement?.sectionId,
			answer: [""] as any,
			answeredBy: user?.id,
			repeatKey: activeElement?.repeatKey,
		},
	});

	const answer = useWatch({ control, name: "answer" })?.filter((a) => (a as any) !== "");
	const isValid = question?.required ? (answer?.length || 0) > 0 : true;
	const isSkippable = question?.required
		? false
		: (answer?.filter((a) => (a as any) !== "").length || 0) > 0
			? false
			: !question?.required;

	async function performUpdate() {
		try {
			await ax.patch(`/survey/${survey?.id}/workspace/${workspaceId}/response/${response?.id}`, {
				data: { values: getValues(), surveyPath },
			});
		} catch (err) {
			console.error(err);
		}
	}

	async function handleChoice(option: { id?: string; value?: string }) {
		try {
			if (question?.allowMultipleChoices) {
				let answers = [...(answer as unknown as string[])];
				if (answers.includes(option.value || "")) {
					answers = answers.filter((a) => a !== option.value);
				} else {
					answers.push(option.value || "");
				}
				setValue("answer", answers as any);
			} else {
				if (answer?.length === 0) {
					setValue("answer", [option.value as any]);
				} else {
					if (answer?.includes((option.value || "") as any)) {
						setValue("answer", []);
					} else {
						setValue("answer", [option.value as any]);
					}
				}
			}
			setTimeout(performUpdate, 100);
		} catch (error) {
			console.error(error);
		}
	}

	/** Colors */
	const buttonBackgroundColor = useColorModeValue("gray.200", "gray.700");
	const buttonBackgroundColorSelected = useColorModeValue("gray.800", "gray.200");
	const buttonTextColor = useColorModeValue("gray.800", "gray.200");
	const buttonTextColorSelected = useColorModeValue("gray.200", "gray.800");

	/** Render */
	return (
		<>
			<VStack
				align="stretch"
				spacing={0}
			>
				<Prompt />
				<RequiredIndicator />
			</VStack>
			<Description />
			<VStack
				maxWidth="300px"
				align="stretch"
				spacing={3}
				overflowY="scroll"
			>
				{question?.allowMultipleChoices && <MultipleChoicesPossible />}
				{question?.options?.map((option) => {
					const isSelected = answer?.includes((option.value || "") as any);
					return (
						<Button
							flexShrink={0}
							key={option.id}
							colorScheme="gray"
							color={isSelected ? buttonTextColorSelected : buttonTextColor}
							bg={isSelected ? buttonBackgroundColorSelected : buttonBackgroundColor}
							variant="unstyled"
							onClick={async () => {
								handleChoice({ id: option.id, value: option.value });
							}}
						>
							{option.value}
						</Button>
					);
				})}
			</VStack>
			<Spacer />
			<QuestionStepper
				onUpdate={performUpdate}
				isSkippable={isSkippable}
				forwardDisabled={!isValid}
			/>
		</>
	);
};
