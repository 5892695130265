import { useEditConstructionSiteContactModalContext } from "@/modals/construction-sites/edit-construction-site-contact-modal/context";
import {
	constructionSiteContactTypeArray,
	getCity,
	getConstructionSiteContactTypeLabel,
	getDeliveryMethodTypeString,
} from "@msuite/katana";
import {
	Divider,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	Input,
	Select,
	Switch,
	VStack,
	useApiKey,
} from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormError, type IConstructionSiteContactForm } from "./schema";

export const ConstructionSiteContactForm: FC = () => {
	/** Context */
	const { shouldOverride } = useEditConstructionSiteContactModalContext();
	const methods = useFormContext<IConstructionSiteContactForm>();
	const apiKey = useApiKey("zipCodeBase");
	const [
		isInvoiceRecipient,
		hasAlternateInvoiceAddress,
		invoiceDeliveryMethod,
		alwaysIncludeEmailInInvoice,
	] = useWatch({
		control: methods.control,
		name: [
			"isInvoiceRecipient",
			"hasAlternateInvoiceAddress",
			"invoiceDeliveryMethod",
			"alwaysIncludeEmailInInvoice",
		],
	});

	/** Functions */
	async function handleUpdateCity(prefix: "address" | "alternateInvoiceAddress") {
		try {
			const zipCode = methods.getValues(`${prefix}.zipCode`);
			const city = await getCity(zipCode, apiKey);
			methods.setValue(`${prefix}.city`, city);
		} catch (error) {
			console.error(error);
		}
	}

	/** Render */
	return (
		<VStack paddingBottom="10vh">
			<FormControl>
				<FormLabel>Unternehmensname</FormLabel>
				<Input
					{...methods.register("name")}
					placeholder="z.B.: Muster GmbH"
				/>
				<FormError name="name" />
			</FormControl>
			<FormControl isRequired={shouldOverride}>
				<FormLabel>Typ</FormLabel>
				<Select
					{...methods.register("type")}
					placeholder="Bitte auswählen"
					defaultValue={constructionSiteContactTypeArray.at(-1)}
				>
					{constructionSiteContactTypeArray.map((type) => (
						<option
							key={type}
							value={type}
						>
							{getConstructionSiteContactTypeLabel(type)}
						</option>
					))}
				</Select>
				<FormError name="type" />
			</FormControl>
			<Grid
				gridTemplateColumns="2fr 1fr"
				gap={6}
			>
				<FormControl>
					<FormLabel>Straße</FormLabel>
					<Input
						{...methods.register("address.street")}
						placeholder="z.B.: Musterstraße"
					/>
					<FormError name="address.street" />
				</FormControl>
				<FormControl>
					<FormLabel>Hausnummer</FormLabel>
					<Input
						{...methods.register("address.number")}
						placeholder="z.B.: 13a"
					/>
					<FormError name="address.number" />
				</FormControl>
			</Grid>
			<Grid
				gridTemplateColumns="1fr 1fr"
				gap={6}
			>
				<FormControl>
					<FormLabel>PLZ</FormLabel>
					<Input
						{...methods.register("address.zipCode")}
						placeholder="z.B.: 13407"
						onBlur={() => handleUpdateCity("address")}
					/>
					<FormError name="address.zipCode" />
				</FormControl>
				<FormControl>
					<FormLabel>Ort</FormLabel>
					<Input
						{...methods.register("address.city")}
						placeholder="z.B.: Berlin"
					/>
					<FormError name="address.street" />
				</FormControl>
			</Grid>
			<Divider />
			<FormControl>
				<FormLabel>E-Mail</FormLabel>
				<Input
					{...methods.register("email")}
					placeholder="z.B.: info@muster.de"
				/>
				<FormError name="email" />
			</FormControl>
			<FormControl>
				<FormLabel>Telefon</FormLabel>
				<Input
					{...methods.register("phone")}
					placeholder="z.B.: +49 30 / 403 92 659"
				/>
				<FormError name="phone" />
			</FormControl>
			<FormControl>
				<FormLabel>Mobil</FormLabel>
				<Input
					{...methods.register("mobile")}
					placeholder="z.B.: +49 176 20 22 64 60"
				/>
				<FormError name="mobile" />
			</FormControl>
			<FormControl>
				<FormLabel>Fax</FormLabel>
				<Input
					placeholder="z.B.: 030 / 403 92 65 81"
					{...methods.register("fax")}
				/>
				<FormError name="fax" />
			</FormControl>
			<Switch {...methods.register("isInvoiceRecipient")}>Rechnungsempfänger</Switch>
			{isInvoiceRecipient && (
				<Switch {...methods.register("hasAlternateInvoiceAddress")}>
					Abweichende Rechnungsadresse
				</Switch>
			)}
			{hasAlternateInvoiceAddress && (
				<Fragment>
					<Grid
						gridTemplateColumns="2fr 1fr"
						gap={6}
					>
						<GridItem colSpan={2}>
							<FormControl>
								<FormLabel>Firmierung</FormLabel>
								<Input
									{...methods.register("alternateInvoiceAddress.corporateName")}
									placeholder="z.B.: Muster GmbH"
								/>
								<FormError name="alternateInvoiceAddress.corporateName" />
							</FormControl>
						</GridItem>
						<FormControl>
							<FormLabel>Straße</FormLabel>
							<Input
								{...methods.register("alternateInvoiceAddress.street")}
								placeholder="z.B.: Musterstraße"
							/>
							<FormError name="alternateInvoiceAddress.street" />
						</FormControl>
						<FormControl>
							<FormLabel>Hausnummer</FormLabel>
							<Input
								{...methods.register("alternateInvoiceAddress.number")}
								placeholder="z.B.: 13a"
							/>
							<FormError name="alternateInvoiceAddress.number" />
						</FormControl>
					</Grid>
					<Grid
						gridTemplateColumns="1fr 1fr"
						gap={6}
					>
						<FormControl>
							<FormLabel>PLZ</FormLabel>
							<Input
								{...methods.register("alternateInvoiceAddress.zipCode")}
								placeholder="z.B.: 13407"
								onBlur={() => handleUpdateCity("alternateInvoiceAddress")}
							/>
							<FormError name="alternateInvoiceAddress.zipCode" />
						</FormControl>
						<FormControl>
							<FormLabel>Ort</FormLabel>
							<Input
								{...methods.register("alternateInvoiceAddress.city")}
								placeholder="z.B.: Berlin"
							/>
							<FormError name="alternateInvoiceAddress.city" />
						</FormControl>
					</Grid>
				</Fragment>
			)}
			<Divider />
			{isInvoiceRecipient && (
				<FormControl>
					<FormLabel>Rechnungsversand</FormLabel>
					<Select
						{...methods.register("invoiceDeliveryMethod")}
						placeholder="Bitte auswählen"
					>
						<option value="email">{getDeliveryMethodTypeString("email")}</option>
						<option value="mail">{getDeliveryMethodTypeString("mail")}</option>
						<hr />
						<option value="other">{getDeliveryMethodTypeString("other")}</option>
					</Select>
				</FormControl>
			)}

			{(invoiceDeliveryMethod === "email" || alwaysIncludeEmailInInvoice) && (
				<FormControl>
					<FormLabel>Abweichende E-Mail für Rechnungen</FormLabel>
					<Input
						{...methods.register("alternateEmailForInvoice")}
						placeholder="z.B.: rechnung@mustermann.de"
					/>
					<FormError name="alternateEmailForInvoice" />
				</FormControl>
			)}

			{invoiceDeliveryMethod === "other" && isInvoiceRecipient && (
				<FormControl>
					<FormLabel>Alternative Rechnungsversandart</FormLabel>
					<Input
						{...methods.register("alternateInvoiceDeliveryMethod")}
						placeholder="z.B.: Fax"
					/>
					<FormError name="alternateInvoiceDeliveryMethod" />
				</FormControl>
			)}

			{invoiceDeliveryMethod !== "email" && isInvoiceRecipient && (
				<Switch {...methods.register("alwaysIncludeEmailInInvoice")}>
					Rechnung vorab per E-Mail zusenden
				</Switch>
			)}
		</VStack>
	);
};
