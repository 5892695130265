import { db } from "@/core";
import { useEditConstructionSiteContactModalContext } from "@/modals/construction-sites/edit-construction-site-contact-modal/context";
import type { ConstructionSite } from "@msuite/katana";
import {
	Text,
	WhiteIconButton,
	toast,
	useAsyncModal,
	useAuthContext,
	useDocument,
	useUIContext,
} from "@msuite/picasso";
import { deleteDoc, doc } from "firebase/firestore";
import { type FC, useState } from "react";
import { IoTrash } from "react-icons/io5";

/** Props Interface */
interface UnlinkContactButtonProps {
	contactId: string | undefined;
	constructionSiteId: string | undefined;
	onComplete: () => void;
}

export const UnlinkContactButton: FC<UnlinkContactButtonProps> = ({
	constructionSiteId,
	contactId,
}) => {
	/** Context */
	const { shouldOverride } = useEditConstructionSiteContactModalContext();
	const { validation } = useAuthContext();
	const { colors } = useUIContext();

	/** Hooks */
	const asyncModal = useAsyncModal();
	const { data: constructionSite } = useDocument<ConstructionSite>(db, {
		path: `baustellen/${constructionSiteId}`,
		subscribe: true,
	});

	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	if (!shouldOverride) return null;
	if (!constructionSiteId) return null;
	if (!validation?.isAdmin) return null;
	if (constructionSite?.clientId === contactId) return null;

	/** Functions */
	async function handleOnUnlinkContact() {
		try {
			setIsLoading(true);
			if (!constructionSiteId) return;
			if (!contactId) throw new Error("Contact ID not found");
			const isConfirmed = await asyncModal({
				headerText: "Kontakt entfernen",
				bodyText:
					"Möchten Sie diesen Kontakt wirklich aus dem Bauvorhaben entfernen? Bereits getätigte Überschreibungen werden dadurch gelöscht.",
			});
			if (!isConfirmed) return;
			const contactOverrideRef = doc(
				db,
				`baustellen/${constructionSiteId}/contactOverrides/${contactId}`,
			);
			await deleteDoc(contactOverrideRef);
		} catch (error) {
			toast.error("Fehler beim Entfernen des Kontakts");
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<WhiteIconButton
			icon={
				<Text color={colors.red}>
					<IoTrash />
				</Text>
			}
			onClick={handleOnUnlinkContact}
			size="sm"
			aria-label="Kontakt entfernen"
			isLoading={isLoading}
		/>
	);
};
