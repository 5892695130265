import { createContext, useContext } from "react";

/** Interface */
interface IConstructionSiteDocumentsContext {
	basePath: string;
	type: "project-request" | "construction-site-documents" | "construction-site-contract-documents";
	id: string;
}

/** Context */
export const ConstructionSiteDocumentsContext = createContext<IConstructionSiteDocumentsContext>(
	{} as IConstructionSiteDocumentsContext,
);

/** Hooks */
export function useConstructionSiteDocumentsContext() {
	return useContext(ConstructionSiteDocumentsContext);
}
