import { ClientAutoCompleteItem } from "@/components/construction-site/client-auto-complete-item";
import { db } from "@/core";
import type { ConstructionSiteContact } from "@msuite/katana";
import { AutoComplete, type AutoCompleteOption, VStack, useCollection } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface ConstructionSiteContactSearchProps {
	onSelect: (contactId: string, contact: ConstructionSiteContact) => void;
}

export const ConstructionSiteContactSearch: FC<ConstructionSiteContactSearchProps> = ({
	onSelect,
}) => {
	/** Context */
	const { data: contacts } = useCollection<ConstructionSiteContact>(db, {
		path: "_contactBook",
		subscribe: true,
		orderBy: "name",
		orderByDirection: "asc",
	});

	/** Functions */
	function handleOnSelect(
		selection:
			| AutoCompleteOption<
					ConstructionSiteContact & {
						id: string;
					}
			  >
			| undefined,
	) {
		if (selection?.item) {
			onSelect(selection.value, selection.item);
		}
	}

	/** Render */
	return (
		<VStack>
			<AutoComplete
				alwaysShowEmptyElement
				placeholder="Kontakt suchen"
				options={contacts.map((contact) => ({
					value: contact.id,
					item: contact,
					label: contact.name,
				}))}
				renderItem={ClientAutoCompleteItem}
				onChange={handleOnSelect}
			/>
		</VStack>
	);
};
