import { ax } from "@/core";
import type { ISiteManagersApiResponse } from "@msuite/katana";
import { FormControl, FormLabel, Select, useApi } from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { EditorSuggestionHint } from "./editor-suggestions-hint";
import { FormError, type ProjectRequestFormType } from "./schema";

export const EditorPicker: FC = () => {
	/** Context */
	const methods = useFormContext<ProjectRequestFormType>();

	/** Hooks */
	const { data } = useApi<ISiteManagersApiResponse>(ax, {
		url: "/v2/employees",
		dependencies: ["employees"],
	});

	/** Render */
	return (
		<FormControl>
			<FormLabel>Bearbeiter</FormLabel>
			<Select
				placeholder="Bitte auswählen"
				{...methods.register("editor")}
			>
				{data?.employees?.map((employee) => (
					<option
						key={employee.id}
						value={employee.id}
					>
						{employee.vorname} {employee.nachname}
					</option>
				))}
			</Select>
			<EditorSuggestionHint />
			<FormError name="editor" />
		</FormControl>
	);
};
