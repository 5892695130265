import { ax, storage } from "@/core";
import { DocumentsFolderModal } from "@/modals/construction-sites/documents-folder-modal/documents-folder-modal";
import type { ConstructionSiteDocument } from "@msuite/katana";
import {
	ButtonGroup,
	HStack,
	IconButton,
	Text,
	useAsyncModal,
	useDisclosure,
	useUIContext,
} from "@msuite/picasso";
import { getDownloadURL, ref } from "firebase/storage";
import { type FC, Fragment, useState } from "react";
import { IoTrash } from "react-icons/io5";
import { TbExternalLink } from "react-icons/tb";
import { useConstructionSiteDocumentsContext } from "./context";

/** Props Interface */
interface RowActionsProps {
	document: ConstructionSiteDocument;
}

export const RowActions: FC<RowActionsProps> = ({ document }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Context */
	const { basePath, type, id } = useConstructionSiteDocumentsContext();
	const { colors } = useUIContext();
	const asyncModal = useAsyncModal();
	const documentsFolderModal = useDisclosure();

	/** Functions */
	async function onOpenDocument() {
		const downloadUrl = await getDownloadURL(ref(storage, document.file_path));
		window.open(downloadUrl, "_blank");
	}

	async function onDelete() {
		try {
			setIsLoading(true);
			if (!document?.id) return;
			const bodyText = document.file_name
				? `Möchten Sie das Dokument "${document.file_name}" wirklich löschen?`
				: "Möchten Sie den Ordner wirklich löschen?";
			const isConfirmed = await asyncModal({
				headerText: "Dokument löschen",
				bodyText,
				confirmButtonText: "Löschen",
			});
			if (!isConfirmed) return;
			await ax.put(`/v2/construction-sites/documents/${document.id}`, {
				data: {
					type,
					id,
					path: basePath,
				},
			});
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<Fragment>
			<HStack justify="flex-end">
				<ButtonGroup
					size="xs"
					alignItems="center"
				>
					{document.file_path ? (
						<IconButton
							icon={<TbExternalLink />}
							text="Datei öffnen"
							aria-label="Öffnen"
							onClick={onOpenDocument}
						/>
					) : document.is_directory ? (
						<IconButton
							icon={<TbExternalLink />}
							text="Ordner öffnen"
							aria-label="Öffnen"
							onClick={documentsFolderModal.onOpen}
						/>
					) : null}
					<IconButton
						icon={
							<Text color={colors.red}>
								<IoTrash />
							</Text>
						}
						variant="ghost"
						colorScheme="red"
						aria-label="Löschen"
						onClick={onDelete}
						isLoading={isLoading}
						size="sm"
					/>
				</ButtonGroup>
			</HStack>
			<DocumentsFolderModal
				isOpen={documentsFolderModal.isOpen}
				onClose={documentsFolderModal.onClose}
				document={document}
				basePath={basePath}
			/>
		</Fragment>
	);
};
