import { ax } from "@/core";
import { AbsenceForm } from "@/forms/absence-form";
import { absenceFormSchema } from "@/forms/absence-form/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { type Absence, moment } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	handleOnReject,
	toast,
	useAbsenceStatusOptions,
	useAuthContext,
} from "@msuite/picasso";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface AddAbsenceModalProps {
	isOpen: boolean;
	onClose: () => void;
	onComplete?: (employeeIds: string[]) => void;
	employeeId: string | undefined;
	hasMultipleSelection?: boolean;
}

export const AddAbsenceModal: FC<AddAbsenceModalProps> = ({
	isOpen,
	onClose,
	onComplete,
	employeeId,
	hasMultipleSelection,
}) => {
	/** Context */
	const { validation } = useAuthContext();

	/** State */
	const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>(
		!hasMultipleSelection && employeeId ? [employeeId] : [],
	);

	/** Hooks */
	const { defaultOption } = useAbsenceStatusOptions();
	const methods = useForm<Partial<Absence>>({
		resolver: yupResolver(absenceFormSchema(hasMultipleSelection ?? false) as any) as any,
		defaultValues: {
			von: moment().format("YYYY-MM-DD"),
			bis: moment().add(1, "w").format("YYYY-MM-DD"),
			type: "urlaub",
			category: "kurzfristiger_urlaub",
			status: defaultOption,
			mitarbeiterId: employeeId,
			comment: "",
		},
	});

	/** Functions */
	async function handleOnSubmit(values: Partial<Absence>) {
		try {
			if (!values.mitarbeiterId) values.mitarbeiterId = employeeId;
			let _selectedEmployeeIds = (hasMultipleSelection ? selectedEmployeeIds : [employeeId]).filter(
				Boolean,
			) as string[];
			if (!validation?.isPersonal)
				_selectedEmployeeIds = _selectedEmployeeIds.filter((id) => id === employeeId);
			if (_selectedEmployeeIds.length === 0) {
				toast.error("Es wurde kein Mitarbeiter ausgewählt.");
				throw new Error("Es wurde kein Mitarbeiter ausgewählt.");
			}
			await ax.post(`/v2/employees/${employeeId}/absence`, {
				data: { values, employeeIds: _selectedEmployeeIds },
			});
			await onComplete?.(_selectedEmployeeIds);
			onClose();
		} catch (error) {
			console.error(error);
		}
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleOnSubmit, handleOnReject)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Abwesenheit hinzufügen</ModalHeader>
						<ModalBody>
							<AbsenceForm
								hasEmployeeSelection={!employeeId}
								selectedEmployeeIds={selectedEmployeeIds}
								setSelectedEmployeeIds={setSelectedEmployeeIds}
							/>
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={onClose}
								>
									abbrechen
								</Button>
								<Button
									isLoading={methods.formState.isSubmitting}
									type="submit"
								>
									Beantragen
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};
