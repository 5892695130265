import { LoadingScreen } from "@/components/loading-screen";
import { db } from "@/core";
import type { ProjectRequest as ProjectRequestType } from "@msuite/katana";
import {
	Box,
	Grid,
	HStack,
	InnerSiteLayout,
	SegmentedControl,
	VStack,
	useDocument,
	useTitle,
} from "@msuite/picasso";
import { useQueryState } from "nuqs";
import { type FC, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSalesConfiguration } from "../sales/use-sales-configuration";
import { Actions } from "./actions";
import { Calendar } from "./calendar";
import { Dashboard } from "./dashboard";
import { ProjectRequestDocuments } from "./project-request-documents";
import { ProjectRequestHeader } from "./project-request-header";
import { ProjectRequestStateDiagram } from "./project-request-state-diagram";
import { Timeline } from "./timeline";

export const ProjectRequest: FC = () => {
	/** State */
	const [tab, setTab] = useQueryState("tab", {
		defaultValue: "dashboard",
		clearOnDefault: false,
	});

	/** Hooks */
	const headerRef = useRef<HTMLDivElement>(null);
	const { projectRequestId } = useParams();
	const { data: projectRequest } = useDocument<ProjectRequestType>(db, {
		path: `projectRequests/${projectRequestId}`,
		subscribe: true,
		queryClientOptions: {
			enabled: !!projectRequestId,
		},
	});
	useTitle(`Anfrage - ${projectRequest?.projectName}`);
	const isFetched = useSalesConfiguration();

	if (!isFetched) return <LoadingScreen />;

	/** Fallback */
	if (!projectRequestId) return null;

	/** Render */
	return (
		<InnerSiteLayout>
			<ProjectRequestHeader
				projectRequest={projectRequest}
				projectRequestId={projectRequestId}
			/>
			<ProjectRequestStateDiagram projectRequestId={projectRequestId} />
			<Grid
				flex={1}
				templateRows="max-content 1fr"
				templateColumns="3fr 1fr"
				gap={6}
			>
				<HStack justifyContent="space-between">
					<SegmentedControl
						value={tab ?? "dashboard"}
						onChange={setTab}
						options={[
							{
								label: "Dashboard",
								value: "dashboard",
							},
							{
								label: "Timeline",
								value: "timeline",
							},
							{
								label: "Dokumente",
								value: "documents",
							},
						]}
					/>
					<div ref={headerRef} />
				</HStack>
				<HStack justifyContent="flex-end">
					<Actions projectRequestId={projectRequestId} />
				</HStack>
				<ContentContainer>
					{tab === "dashboard" && <Dashboard />}
					{tab === "timeline" && (
						<Timeline
							projectRequestId={projectRequestId}
							headerRef={headerRef}
						/>
					)}
					{tab === "documents" && (
						<ProjectRequestDocuments
							headerRef={headerRef}
							projectRequestId={projectRequestId}
						/>
					)}
				</ContentContainer>
				<Calendar projectRequestId={projectRequestId} />
			</Grid>
		</InnerSiteLayout>
	);
};

/** Props Interface */
interface ContentContainerProps {
	children?: React.ReactNode;
}

export const ContentContainer: FC<ContentContainerProps> = ({ children }) => {
	return (
		<VStack
			spacing={0}
			overflowY="hidden"
			flexGrow={0}
			minH="0"
		>
			<Box
				height="0"
				flexGrow={1}
			>
				<VStack
					height="100%"
					overflowY="scroll"
					flexGrow={0}
					minH="0"
					spacing={0}
				>
					{children}
				</VStack>
			</Box>
		</VStack>
	);
};
