import { HStack, Text } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface UploadOverviewProps {
	uploadedFiles: number;
	maxFiles: number;
}

export const UploadOverview: FC<UploadOverviewProps> = ({ maxFiles, uploadedFiles }) => {
	/** Render */
	return (
		<HStack justifyContent="flex-end">
			<Text fontSize="xs">
				Hochgeladen {uploadedFiles} von {maxFiles}
			</Text>
		</HStack>
	);
};
