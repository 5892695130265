import { useConstructionSiteCardContext } from "@/components/card/construction-site-card/context";
import { usePlanContext } from "@/sites/plan/context";
import { DropdownMenu, useDisclosure, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { ActionBarButton } from "../action-bar-button";
import { useQuickAssign } from "./use-quick-assign";

export const QuickAssignButton: FC = () => {
	/** Constants */
	const { activeIsBlocked } = usePlanContext();
	const { colors } = useUIContext();
	const { isInView } = useConstructionSiteCardContext();

	/** Hooks */
	const { options } = useQuickAssign();
	const dropdownMenu = useDisclosure();

	/** Render */
	return (
		<DropdownMenu
			options={options}
			width="20rem"
			isDisabled={activeIsBlocked}
			shouldRender={isInView}
		>
			<ActionBarButton
				color={colors.green}
				isDisabled={activeIsBlocked}
				onClick={dropdownMenu.onOpen}
			/>
		</DropdownMenu>
	);
};
