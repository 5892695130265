import { useAppContext } from "@/context";
import { ax, queryClient } from "@/core";
import {
	type DashboardCallNotes,
	type DashboardSubcontractorsInternal,
	moment,
} from "@msuite/katana";
import {
	type MenuItems,
	type NavigationLinks,
	WorkspaceMenuItem,
	useApi,
	useAuthContext,
	useCoreContext,
} from "@msuite/picasso";
import { signOut } from "firebase/auth";
import { IoAirplane, IoPeople } from "react-icons/io5";
import {
	TbArrowBigLeftLinesFilled,
	TbBookFilled,
	TbBuildingBank,
	TbCurrencyEuro,
	TbFileFilled,
	TbFileInvoice,
	TbFolderFilled,
	TbLogout,
	TbPhoneFilled,
} from "react-icons/tb";
import packageJson from "../../../package.json";

export function useNavigationLinks() {
	/** Context */
	const { auth } = useCoreContext();
	const { workspaceIds, validation } = useAuthContext();
	const { activeWorkspace, setActiveWorkspace } = useAppContext();

	const { data: callNotesData } = useApi<DashboardCallNotes>(ax, {
		url: "/v2/dashboard/call-notes",
		dependencies: ["dashboard", "call-notes"],
		refetchOnMount: true,
		staleTime: 1000,
	});

	/** Hooks */
	const { data: subcontractorData } = useApi<DashboardSubcontractorsInternal>(ax, {
		url: "/v2/dashboard/timesheets",
		dependencies: ["dashboard", "timesheets"],
		refetchOnMount: true,
		staleTime: 1000,
	});

	/** Render */
	function createMenuItems(): MenuItems {
		if (!validation) return [];
		return [
			{
				id: "app-information",
				render: `${packageJson.name} ${packageJson.version}`,
				onClick: undefined,
			},
			"divider",
			...buildWorkspaceMenuItems(),
			"divider",
			{
				icon: <TbLogout />,
				label: "Logout",
				onClick: () => {
					queryClient.clear();
					signOut(auth);
				},
			},
		];
	}

	function createNavigationLinks(): NavigationLinks {
		if (!validation) return [];
		if (validation.isSubcontractor) return [];
		return [
			{
				label: "Home",
				icon: null,
				to: "/home",
				associatedRoutes: ["/"],
				count:
					(subcontractorData?.timesheetsUncontrolled?.length ?? 0) +
					(callNotesData?.unansweredCallNotes ?? 0),
			},
			{
				label: "Wochenplan",
				icon: null,
				to: "/plan",
				isInvalid: validation?.isMitarbeiter,
			},
			{
				label: "Baustellen",
				icon: null,
				to: "/construction-sites",
				associatedRoutes: ["/construction-sites/*"],
				isInvalid: validation?.isMitarbeiter,
			},
			{
				label: "Personal",
				icon: null,
				to: "/personal/employees",
				associatedRoutes: ["/personal/*"],
				isInvalid: !validation?.isPersonal && !validation?.isFrontdesk,
				children: [
					{
						label: "Mitarbeiter",
						icon: <IoPeople />,
						to: "/personal/employees",
						isInvalid: !validation?.isPersonal,
					},
					{
						label: "Abwesenheiten",
						icon: <IoAirplane />,
						to: "/personal/absences",
						isInvalid: !validation?.isPersonal && !validation?.isFrontdesk,
					},
					{
						label: "Fortbildungen",
						icon: <TbBookFilled />,
						to: "/personal/educations",
						isInvalid: !validation?.isPersonal,
					},
					"divider",
					{
						label: "Subunternehmer",
						icon: <TbFileFilled />,
						to: "/personal/subcontractors",
						isInvalid: !validation?.isPersonal,
					},
				],
			},
			{
				label: "Mein Bereich",
				icon: null,
				to: "/my-space/site-measurements",
				associatedRoutes: ["/my-space/*"],
				isInvalid: validation?.isMitarbeiter,
				children: [
					{
						label: "Aufmasse",
						to: "/my-space/site-measurements",
						icon: <TbFileFilled />,
						isInvalid: !validation?.isBauleiter,
					},
					{
						label: "Angebote",
						to: "/my-space/offers",
						icon: <TbArrowBigLeftLinesFilled />,
						isInvalid: !validation?.isVerwaltung && !validation?.isSales,
					},
					{
						label: "Bestellungen",
						to: "/my-space/orders",
						icon: <TbFolderFilled />,
						isInvalid: !validation.isLogistics && !validation.isAdmin,
					},
					"divider",
					{
						label: "Vertretung",
						to: "/my-space/deputies",
						icon: <IoPeople />,
						isInvalid: !validation?.isBauleiter,
					},
					{
						label: "Gesprächsnotizen",
						to: "/my-space/call-notes",
						icon: <TbPhoneFilled />,
						count: callNotesData?.unansweredCallNotes,
					},
				],
			},
			{
				label: "Rechnungslegung",
				to: "/accounting/site-measurements",
				icon: null,
				associatedRoutes: ["/accounting/*"],
				isInvalid: !validation?.isVerwaltung,
				children: [
					{
						label: "Aufmasse",
						to: "/accounting/site-measurements",
						icon: <TbFileFilled />,
					},
					{
						label: "Rechnungen",
						to: "/accounting/invoices",
						icon: <TbCurrencyEuro />,
					},
					{
						label: "Miete",
						to: "/accounting/rent",
						icon: <TbBuildingBank />,
					},
					"divider",
					{
						label: `Jahresendabrechnung ${moment().year()}`,
						to: "/accounting/yearly-statements",
						icon: <TbFileInvoice />,
					},
				],
			},
			{
				label: "Kontakte",
				to: "/contacts",
				icon: null,
				associatedRoutes: ["/contacts/*"],
				isInvalid: !!validation?.isMitarbeiter,
			},
			{
				label: "CMS",
				to: "/cms",
				associatedRoutes: ["/cms/*"],
				isInvalid: !validation?.isPersonal,
				icon: null,
			},
			{
				label: "Sales",
				to: "/sales",
				associatedRoutes: ["/sales/*"],
				isInvalid: false,
				icon: null,
			},
		];
	}

	function buildWorkspaceMenuItems(): MenuItems {
		return workspaceIds.map((workspaceId) => ({
			id: workspaceId,
			render: (
				<WorkspaceMenuItem
					workspaceId={workspaceId}
					isActive={activeWorkspace === workspaceId}
				/>
			),
			onClick: () => {
				setActiveWorkspace(workspaceId);
			},
		}));
	}

	const navigationLinks = createNavigationLinks();
	const menuItems = createMenuItems();

	return {
		navigationLinks,
		menuItems,
	};
}

export function isLocalhost(): boolean {
	return window.location.hostname === "localhost";
}
