import { Documents } from "@/components/construction-site";
import { db } from "@/core";
import type { ConstructionSiteDocument } from "@msuite/katana";
import { useCollection } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface ProjectRequestDocumentsProps {
	headerRef: React.RefObject<HTMLDivElement>;
	projectRequestId: string;
}

export const ProjectRequestDocuments: FC<ProjectRequestDocumentsProps> = ({
	headerRef,
	projectRequestId,
}) => {
	/** Hooks */
	const { data: documents, isLoading } = useCollection<ConstructionSiteDocument>(db, {
		path: `projectRequests/${projectRequestId}/documents`,
		subscribe: true,
	});

	/** Render */
	return (
		<Documents
			id={projectRequestId}
			type="project-request"
			headerRef={headerRef}
			isLoading={isLoading}
			documents={documents}
		/>
	);
};
