import { ax, db } from "@/core";
import type { Employee } from "@msuite/katana";
import { DocumentString, HStack, Text, VStack, useApi, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import type { ProjectRequestFormType } from "./schema";

export const EditorSuggestionHint: FC = () => {
	/** Context */
	const { colors } = useUIContext();
	const methods = useFormContext<ProjectRequestFormType>();
	const [clientId, editor] = useWatch({ control: methods.control, name: ["clientId", "editor"] });

	/** Hooks */
	const { data } = useApi<{ suggestedEditor: string; suggestedEditorProjectCount: number }>(ax, {
		url: `/v2/project-requests/suggestions/editor?clientId=${clientId}`,
		dependencies: ["suggestions", clientId ?? "", "editor"],
		disabled: !clientId,
		staleTime: 10_000,
	});

	/** Guard */
	if (!data?.suggestedEditor) return null;
	if (editor) return null;

	/** Functions */
	function handleOnAddSuggestion() {
		if (!data?.suggestedEditor) return;
		methods.setValue("editor", data?.suggestedEditor);
	}

	/** Render */
	return (
		<VStack spacing={0}>
			<HStack
				pt={1}
				spacing={1}
				fontSize="sm"
			>
				<Text color={colors.gray}>Vorgeschlagener Bearbeiter:</Text>
				<HStack
					cursor="pointer"
					onClick={handleOnAddSuggestion}
				>
					<DocumentString<Employee>
						db={db}
						path={`mitarbeiter/${data?.suggestedEditor}`}
						fields={["vorname", "nachname"]}
					/>
				</HStack>
			</HStack>
		</VStack>
	);
};
