import { getFileTypeIconProps, initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import { Icon } from "@fluentui/react/lib/Icon";
import type { generateDocumentTree } from "@msuite/katana";
import { bytesToMegabyteString } from "@msuite/katana";
import { Box, HStack, Spacer, Spinner, Text, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbCheck } from "react-icons/tb";

initializeFileTypeIcons();

/** Props Interface */
interface FileUploadFileViewerFileProps {
	file: ReturnType<typeof generateDocumentTree>["files"][0];
	isSubmitting?: boolean;
	isUploaded?: string[];
}

export const FileUploadFileViewerFile: FC<FileUploadFileViewerFileProps> = ({
	file,
	isSubmitting,
	isUploaded = [],
}) => {
	const { colors } = useUIContext();
	const extension = file.name.split(".").pop();

	const uploadState = !isSubmitting
		? "neutral"
		: isUploaded.some((str) => str.includes(file.name))
			? "uploaded"
			: "pending";

	/** Render */
	return (
		<HStack spacing={2}>
			{uploadState === "neutral" ? (
				<Icon {...getFileTypeIconProps({ extension })} />
			) : uploadState === "uploaded" ? (
				<Box color={colors.emerald}>
					<TbCheck />
				</Box>
			) : (
				<Box>
					<Spinner size="xs" />
				</Box>
			)}
			<Text
				noOfLines={1}
				textOverflow="ellipsis"
				mr={8}
			>
				{file.name}
			</Text>
			<Spacer />
			<Text whiteSpace="nowrap">{bytesToMegabyteString(file.size)}</Text>
		</HStack>
	);
};
