import { useAppContext } from "@/context";
import { ax } from "@/core";
import type { DailyColonPlan, Dayinfo, Moment } from "@msuite/katana";
import { moment } from "@msuite/katana";
import { useRef, useState } from "react";
import { usePublishListener } from "./dailies/daily/use-publish-listener";

export type PlanMode = "normal" | "planning";

function handleFindActiveDate() {
	const date = moment().getNextPossibleWorkday();
	const week = date.clone().endOf("week").isoWeek();
	const year = date.clone().endOf("week").year();
	return { date, week, year };
}

const initialDate = handleFindActiveDate();

export function getWeeklyReferenceString(activeWorkspace: string | null, currentMoment: Moment) {
	return `planung/${activeWorkspace}/wochen/${currentMoment.format("WYYYY")}`;
}

export const usePlan = () => {
	/** Context */
	const { activeWorkspace } = useAppContext();

	/** Refs */
	const planRef = useRef();

	/** State */
	const [activeDailyInfo, setActiveDailyInfo] = useState<Dayinfo | undefined>();
	const [activeDailyColons, setActiveDailyColons] = useState<DailyColonPlan[]>([]);
	const [copyIsProcessed, setCopyIsProcessed] = useState<boolean>(false);

	const [planMode, setPlanMode] = useState<PlanMode>("normal");
	const [week, setWeek] = useState<number>(initialDate.week);
	const [year, setYear] = useState<number>(initialDate.year);
	const [activeDate, setActiveDate] = useState<Moment>(initialDate.date);

	const isPlanModeActive = planMode === "planning";
	const currentMoment =
		week === 1 ? moment(`${year}-01-01`) : moment().clone().isoWeek(week).year(year);
	const weeklyReference: string = getWeeklyReferenceString(activeWorkspace, currentMoment);

	/** Hooks */
	const { isBlocked: activeIsBlocked } = usePublishListener(activeDate);

	async function handleCopyPreviousWeek() {
		try {
			await ax.patch(`/planung/${activeWorkspace}/week/${currentMoment.format("WYYYY")}/copy`, {
				week,
				year,
			});
		} catch (error) {
			console.error(error);
		}
	}

	/** Return */
	return {
		planMode,
		setPlanMode,
		week,
		setWeek,
		year,
		setYear,
		currentMoment,
		activeWorkspace,
		activeDate,
		setActiveDate,
		weeklyReference,
		activeDailyInfo,
		setActiveDailyInfo,
		activeDailyColons,
		setActiveDailyColons,
		handleCopyPreviousWeek,
		copyIsProcessed,
		setCopyIsProcessed,
		isPlanModeActive,
		handleFindActiveDate,
		planRef,
		activeIsBlocked,
	};
};
