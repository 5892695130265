import { Center, GridItem, Text, VStack, useDropzone, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbUpload } from "react-icons/tb";

/** Props Interface */
interface DropareaProps {
	handleOnFilesChange: (files: File[]) => void;
}

export const Droparea: FC<DropareaProps> = ({ handleOnFilesChange }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: handleOnFilesChange,
	});

	/** Render */
	return (
		<GridItem colSpan={2}>
			<Center
				{...getRootProps()}
				paddingY={14}
				borderWidth={1}
				onClick={() => {}}
				borderStyle="dashed"
				rounded="xl"
				backgroundColor={colors.whiteAdjusted}
				color={colors.gray}
				cursor="alias"
			>
				<VStack
					alignItems="center"
					spacing={2}
				>
					<TbUpload />
					<Text
						fontSize="sm"
						color={colors.gray}
					>
						Dateien hier ablegen
					</Text>
				</VStack>
			</Center>
			<input
				{...getInputProps()}
				multiple
				// @ts-ignore
				webkitdirectory=""
				style={{
					display: "none",
				}}
			/>
		</GridItem>
	);
};
