import { type File, generateDocumentTree } from "@msuite/katana";
import { Label, VStack } from "@msuite/picasso";
import type { FC } from "react";
import { FileUploadFileViewerDirectory } from "./file-upload-file-viewer-directory";

/** Props Interface */
interface FileUploadFileViewerProps {
	files: File[];
	isSubmitting: boolean;
	isUploaded: string[];
}

export const FileUploadFileViewer: FC<FileUploadFileViewerProps> = ({
	files,
	isSubmitting,
	isUploaded,
}) => {
	const structure = generateDocumentTree(files);

	/** Guard */
	if (!files.length) return null;

	/** Render */
	return (
		<VStack spacing={2}>
			<Label>Dateien</Label>
			<FileUploadFileViewerDirectory
				directory={structure}
				isSubmitting={isSubmitting}
				isUploaded={isUploaded}
				isRoot
			/>
		</VStack>
	);
};
