import { QuestionContext } from "@/components/survey/survey-display/question/context";
import { HStack, Text } from "@msuite/picasso";
import { type FC, useContext } from "react";

export const RequiredIndicator: FC = () => {
	/** Context */
	const { question } = useContext(QuestionContext);
	const isRequired = question?.required || false;

	/** Fallback */
	if (!isRequired) return null;

	/** Render */
	return (
		<HStack
			fontSize="xs"
			spacing={0}
		>
			<Text color="red.500">*</Text>
			<Text color="gray.500">Antwort ist erforderlich</Text>
		</HStack>
	);
};
