import { ClientAutoCompleteItem } from "@/components/construction-site/client-auto-complete-item";
import { db } from "@/core";
import { AddConstructionSiteContactModal } from "@/modals/construction-sites/add-construction-site-contact-modal";
import type { ConstructionSiteContact } from "@msuite/katana";
import {
	AutoComplete,
	type AutoCompleteEmptyElement,
	type AutoCompleteOption,
	FormControl,
	FormHelperText,
	FormLabel,
	Input,
	Portal,
	VStack,
	useCollection,
	useDisclosure,
} from "@msuite/picasso";
import { type FC, Fragment, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { TbArrowRight, TbPlus } from "react-icons/tb";
import { ProjectRequestFormClientContacts } from "./project-request-form-client-contacts";
import { FormError, type ProjectRequestFormType } from "./schema";

/** Props Interface */
interface ProjectRequestFormClientProps {
	type: "client" | "response-client";
}

export const ProjectRequestFormClient: FC<ProjectRequestFormClientProps> = ({ type }) => {
	/** State */
	const [showClientNameField, setShowClientNameField] = useState<boolean>(false);

	/** Hooks */
	const addContactModal = useDisclosure();
	const methods = useFormContext<ProjectRequestFormType>();
	const [clientId, respondClientId] = useWatch({
		control: methods.control,
		name: ["clientId", "responseClientId"],
	});
	const { data: contacts, isFetching } = useCollection<ConstructionSiteContact>(db, {
		path: "_contactBook",
		orderBy: "name",
		subscribe: type === "client",
	});

	/** Computed */
	const label = type === "client" ? "Auftraggeber" : "Angebotsempfänger";
	const currentId = type === "client" ? clientId : respondClientId;
	const id = type === "client" ? "clientId" : "responseClientId";

	/** Functions */
	function handleSelectClientId(
		changeItem: AutoCompleteOption<ConstructionSiteContact> | undefined,
	) {
		if (!changeItem) return;
		if (!changeItem?.value) return;
		methods.setValue(id, changeItem?.value);
		if (type === "client") {
			methods.setValue("clientName", undefined);
			methods.resetField("clientName");
		}
		setShowClientNameField(false);
	}

	function handleOnClickEmptyElement(searchValue: string) {
		if (type !== "client") return;
		setShowClientNameField(true);
		setTimeout(() => {
			methods.setValue("clientName", searchValue);
			methods.setValue("clientId", undefined);
		}, 0);
		const legacyClientElement = document.getElementById("client-name-field");
		if (legacyClientElement) {
			setTimeout(() => {
				legacyClientElement.scrollIntoView({ behavior: "smooth" });
				legacyClientElement.focus();
			}, 0);
		}
	}

	/** Guard */
	if (isFetching) return null;

	/** Items */
	const addContactItem: AutoCompleteEmptyElement = {
		icon: <TbPlus />,
		label: "Neuen Kontakt hinzufügen",
		onClick: addContactModal.onOpen,
	};

	/** Render */
	return (
		<Fragment>
			<Portal>
				<AddConstructionSiteContactModal
					isOpen={addContactModal.isOpen}
					onClose={addContactModal.onClose}
					onComplete={(value) => {
						if (value) handleSelectClientId({ value, label: "generated" });
					}}
				/>
			</Portal>
			<VStack>
				<FormControl>
					<FormLabel>{label}</FormLabel>
					<AutoComplete
						alwaysShowEmptyElement
						key={currentId}
						placeholder="Auftraggeber suchen"
						defaultValue={
							currentId
								? {
										value: currentId,
										label: contacts.find((contact) => contact.id === currentId)?.name ?? "N.A.",
									}
								: undefined
						}
						options={contacts.map((contact) => ({
							value: contact.id,
							item: contact,
							label: contact.name,
						}))}
						renderItem={ClientAutoCompleteItem}
						onChange={handleSelectClientId}
						emptyElement={
							type === "client"
								? [
										{
											icon: <TbArrowRight />,
											label: "Mit vorläufigem Auftraggeber fortfahren.",
											onClick: handleOnClickEmptyElement,
										},
										addContactItem,
									]
								: [addContactItem]
						}
					/>
					<FormError name={id} />
				</FormControl>

				{showClientNameField && type === "client" && (
					<FormControl>
						<FormLabel>Vorläufiger Auftraggeber</FormLabel>
						<Input
							{...methods.register("clientName")}
							id="client-name-field"
							placeholder="z.B. Muster GmbH"
						/>
						<FormHelperText>
							Diese Angabe wird nur vorübergehend verwendet und ist durch einen Kontakt aus der
							Datenbank zu ersetzen.
						</FormHelperText>
					</FormControl>
				)}
				<ProjectRequestFormClientContacts
					contactId={currentId}
					type={type}
				/>
			</VStack>
		</Fragment>
	);
};
