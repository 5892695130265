import { type ProjectRequest, moment } from "@msuite/katana";
import { Box, HStack, Text, VStack, useUIContext } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface ProjectRequestDeadlineDiagramProps {
	projectRequest: ProjectRequest | undefined;
}

export const ProjectRequestDeadlineDiagram: FC<ProjectRequestDeadlineDiagramProps> = ({
	projectRequest,
}) => {
	/** Context */
	const { colors } = useUIContext();
	const maxDifference = Math.abs(
		moment(projectRequest?.receiptDate).diff(moment(projectRequest?.submissionDate), "days"),
	);
	const currentDifference = Math.max(
		0,
		moment(projectRequest?.submissionDate).diff(moment(), "days"),
	);
	const progressInPercent = Math.round(100 - (currentDifference / maxDifference) * 100);

	/** Render */
	return (
		<VStack spacing={2}>
			<HStack
				justifyContent="space-between"
				fontSize="sm"
			>
				<Text>{moment(projectRequest?.receiptDate).format("DD.MM.YYYY")}</Text>
				<Text>{moment(projectRequest?.submissionDate).format("DD.MM.YYYY")}</Text>
			</HStack>
			<HStack>
				<Box
					flex={1}
					height="3px"
					rounded="full"
					backgroundColor={colors.grayLightDarker}
					overflow="hidden"
				>
					<Box
						width="100%"
						height="100%"
						bgGradient={"linear(to-r, hsl(142, 71%, 45%), hsl(38, 92%, 50%), hsl(0, 72%, 51%))"}
						style={{
							clipPath: `polygon(0 0, ${progressInPercent}% 0, ${progressInPercent}% 100%, 0 100%)`,
						}}
					/>
				</Box>
			</HStack>
		</VStack>
	);
};
