import { EditProjectRequestModal } from "@/modals/sales/edit-project-request-modal";
import { Button, useDisclosure } from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { useNavigate } from "react-router-dom";

/** Props Interface */
interface ActionsProps {
	projectRequestId: string;
}

export const Actions: FC<ActionsProps> = ({ projectRequestId }) => {
	/** Hooks */
	const editProjectRequestModal = useDisclosure();
	const navigate = useNavigate();

	/** Functions */
	function handleOnNavigateCreateOffer() {
		navigate(`/my-space/offers/create-offer?type=project-request&identifier=${projectRequestId}`);
	}

	/** Render */
	return (
		<Fragment>
			<Button onClick={handleOnNavigateCreateOffer}>Angebot hinzufügen</Button>
			<Button onClick={editProjectRequestModal.onOpen}>Bearbeiten</Button>
			{editProjectRequestModal.isOpen && (
				<EditProjectRequestModal
					projectRequestId={projectRequestId}
					isOpen={editProjectRequestModal.isOpen}
					onClose={editProjectRequestModal.onClose}
				/>
			)}
		</Fragment>
	);
};
