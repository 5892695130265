import type { ConstructionSiteContractDocument, ConstructionSiteDocument } from "@msuite/katana";
import { Input, StandardDataTable, VStack, useTableOptions } from "@msuite/picasso";
import { type FC, useMemo } from "react";
import { ConstructionSiteDocumentsContext } from "./context";
import { Header } from "./header";
import { columns } from "./table-config";

/** Props Interface */
interface DocumentsProps {
	documents: (ConstructionSiteDocument | ConstructionSiteContractDocument)[];
	isLoading: boolean;
	headerRef: React.RefObject<HTMLDivElement>;
	id: string;
	type: "project-request" | "construction-site-documents" | "construction-site-contract-documents";
}

export const Documents: FC<DocumentsProps> = ({ headerRef, isLoading, documents, id, type }) => {
	/** Hooks */
	const tableOptions = useTableOptions();

	const basePath = useMemo(() => {
		if (type === "project-request") return `projectRequests/${id}/documents`;
		if (type === "construction-site-documents") return `baustellen/${id}/documents`;
		if (type === "construction-site-contract-documents") return `baustellen/${id}/documents`;
		return "";
	}, [type, id]);

	/** Render */
	return (
		<ConstructionSiteDocumentsContext.Provider value={{ basePath, type, id }}>
			<Header
				headerRef={headerRef}
				id={id}
				type={type}
			/>
			<VStack pt="1px">
				<Input
					isSearch
					value={tableOptions.globalFilter}
					onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
				/>
				<StandardDataTable
					{...tableOptions}
					data={documents}
					columns={columns}
					isLoading={isLoading}
				/>
			</VStack>
		</ConstructionSiteDocumentsContext.Provider>
	);
};
