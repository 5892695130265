import { createContext } from "react";
import type { DropzoneState } from "react-dropzone";

interface IDropzoneModalContext extends Partial<DropzoneState> {
	files: File[];
	onDelete: (file: File) => void;
	performUpload: () => void;
	progress: { value: number; max: number; progress: number; file_nr: number };
	isUploading: boolean;
	isDisabled: boolean;
	maxFiles: number;
	directory?: boolean;
}

export const DropzoneModalContext = createContext<IDropzoneModalContext>({
	files: [],
	onDelete: () => {},
	performUpload: () => {},
	progress: { value: 0, max: 0, progress: 0, file_nr: 0 },
	isUploading: false,
	isDisabled: false,
	maxFiles: 0,
	directory: false,
});
