import { usePlanContext } from "@/sites/plan/context";
import { useState } from "react";

export const useOpacityHandler = (
	constructionSiteId: string | undefined,
	shouldHandleOpacity: boolean,
	hasCurrentAssignment: boolean,
) => {
	/** State */
	const [dailyEditingMap, setDailyEditingMap] = useState<{
		[key: string]: boolean;
	}>({});
	const { isPlanModeActive, activeDate } = usePlanContext();

	/** Constants */
	const isActive = dailyEditingMap[activeDate?.format("YYYY-MM-DD")];

	/** Functions */
	function handleAddToEditingMap() {
		if (!shouldHandleOpacity) return;
		if (!constructionSiteId) return;
		setDailyEditingMap({
			...dailyEditingMap,
			[activeDate?.format("YYYY-MM-DD")]: true,
		});
	}

	function handleRemoveFromEditingMap() {
		if (!shouldHandleOpacity) return;
		if (!constructionSiteId) return;
		setDailyEditingMap({
			...dailyEditingMap,
			[activeDate?.format("YYYY-MM-DD")]: false,
		});
	}

	const opacity =
		!shouldHandleOpacity || !isPlanModeActive || hasCurrentAssignment ? 1 : !isActive ? 0.25 : 1;

	/** Render */
	return { opacity, handleAddToEditingMap, handleRemoveFromEditingMap };
};
