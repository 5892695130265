import { EmailChip } from "@/components/email";
import { ax, db } from "@/core";
import type {
	ConstructionSiteContact,
	ISiteManagersApiResponse,
	ProjectRequest,
} from "@msuite/katana";
import {
	AutoComplete,
	type AutoCompleteOption,
	FormControl,
	FormLabel,
	Wrap,
	useApi,
	useDocument,
} from "@msuite/picasso";
import { type FC, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import type { ProjectRequestActivityFormSchema } from "./schema";

/** Props Interface */
interface ProjectRequestParticipantsProps {
	projectRequestId: string;
}

type Participant = Required<Required<ProjectRequestActivityFormSchema>["participants"]>[number];

export const ProjectRequestParticipants: FC<ProjectRequestParticipantsProps> = ({
	projectRequestId,
}) => {
	/** State */
	const [currentValue] = useState<string>("");

	/** Context */
	const methods = useFormContext<ProjectRequestActivityFormSchema>();
	const [participants] = useWatch({ control: methods.control, name: ["participants"] });

	/** Hooks */
	const { data: employees } = useApi<ISiteManagersApiResponse>(ax, {
		url: "/v2/employees",
		dependencies: ["employees"],
	});

	const { data: projectRequest } = useDocument<ProjectRequest>(db, {
		path: `projectRequests/${projectRequestId}`,
		subscribe: false,
	});

	const { data: contact } = useDocument<ConstructionSiteContact>(db, {
		path: `_contactBook/${projectRequest?.clientId}`,
		subscribe: true,
	});

	/** Options */
	const options = useMemo(() => {
		const items: AutoCompleteOption<Participant>[] = [];
		for (const manager of employees?.employees ?? []) {
			const item: Participant = {
				id: manager.id,
				displayName: `${manager.vorname} ${manager.nachname}`,
				email: manager.email,
			};
			items.push({
				value: item.id,
				label: item.displayName,
				item,
			});
		}
		for (const contactContact of contact?.contacts ?? []) {
			const item: Participant = {
				id: contactContact.id,
				displayName: `${contactContact.firstName} ${contactContact.lastName}`,
				email: contactContact.email,
			};
			items.push({
				value: item.id,
				label: item.displayName,
				item,
			});
		}
		return items.sort((a, b) => a.value.localeCompare(b.value));
	}, [contact, employees?.employees]);

	/** Functions */
	function handleOnAddParticipant(value: AutoCompleteOption<Participant> | undefined) {
		if (!value) return;
		if (!value.item) return;
		const currentParticipants = methods.getValues("participants") ?? [];
		if (currentParticipants.some((item) => item.id === value.item?.id)) return;
		methods.setValue("participants", [...currentParticipants, value.item]);
	}

	function handleOnRemoveParticipant(value: Participant) {
		const currentParticipants = methods.getValues("participants") ?? [];
		if (!currentParticipants.some((item) => item.id === value.id)) return;
		methods.setValue(
			"participants",
			currentParticipants.filter((item) => item.id !== value.id),
		);
	}

	/** Render */
	return (
		<FormControl>
			<FormLabel>Teilnehmer</FormLabel>
			{participants?.length ? (
				<Wrap mb={3}>
					{participants?.map((item, index) => (
						<EmailChip
							onDelete={() => handleOnRemoveParticipant(item)}
							key={item.id}
							email={item.displayName}
							index={index}
						/>
					))}
				</Wrap>
			) : null}
			<AutoComplete<Participant>
				placeholder="Bitte auswählen"
				options={options}
				resetOnSelect
				value={currentValue}
				onChange={(value) => {
					handleOnAddParticipant(value);
				}}
			/>
		</FormControl>
	);
};
