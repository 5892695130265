import { FileNamesTree } from "@/components/files";
import { type ConstructionSiteDocument, generateFileNamesDocumentTree } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface DocumentsFolderModalProps {
	isOpen: boolean;
	onClose: () => void;
	document: ConstructionSiteDocument;
	basePath: string;
}

export const DocumentsFolderModal: FC<DocumentsFolderModalProps> = ({
	isOpen,
	onClose,
	document,
	basePath,
}) => {
	const filePaths =
		document.documents?.map((item) => item.file_path.replaceAll(`${basePath}/`, "")) ?? [];
	const tree = generateFileNamesDocumentTree(filePaths);

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader>Dokumente</ModalHeader>
				<ModalBody>
					<FileNamesTree
						tree={tree}
						basePath={basePath}
					/>
				</ModalBody>
				<ModalFooter>
					<ModalButtonGroup>
						<Button onClick={onClose}>Fertig</Button>
					</ModalButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
