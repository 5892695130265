import type { DocumentTree } from "@msuite/katana";
import { Box, HStack, Text, VStack, useDisclosure, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbFolderFilled } from "react-icons/tb";
import { FileUploadFileViewerFile } from "./file-upload-file-viewer-file";

/** Props Interface */
interface FileUploadFileViewerDirectoryProps {
	directory: DocumentTree;
	isRoot?: boolean;
	isSubmitting: boolean;
	isUploaded: string[];
}

const NESTED_FOLDER_INDENT = 8;
const NESTED_FOLDER_SPACING = 2;

export const FileUploadFileViewerDirectory: FC<FileUploadFileViewerDirectoryProps> = ({
	directory,
	isRoot: _isRoot = false,
	isSubmitting,
	isUploaded,
}) => {
	/** Constants */
	const isRoot = _isRoot || directory.name === "." || !directory.name;

	const folderState = useDisclosure({ defaultIsOpen: true });

	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<VStack
			key={directory.name}
			spacing={NESTED_FOLDER_SPACING}
		>
			{isRoot ? null : (
				<HStack
					spacing={2}
					px={2}
					cursor="pointer"
					rounded="md"
					_hover={{
						bg: colors.grayDark,
					}}
					onClick={folderState.onToggle}
				>
					<Box color={colors.yellow}>
						<TbFolderFilled />
					</Box>
					<Text>{directory.name}</Text>
				</HStack>
			)}
			<VStack
				paddingLeft={isRoot ? 0 : NESTED_FOLDER_INDENT}
				spacing={NESTED_FOLDER_SPACING}
				display={folderState.isOpen ? "flex" : "none"}
			>
				{directory.files.map((file) => (
					<FileUploadFileViewerFile
						file={file}
						key={file.name}
						isSubmitting={isSubmitting}
						isUploaded={isUploaded}
					/>
				))}
			</VStack>
			<VStack
				paddingLeft={isRoot ? 0 : NESTED_FOLDER_INDENT}
				spacing={NESTED_FOLDER_SPACING}
				display={folderState.isOpen ? "flex" : "none"}
			>
				{directory.directories.map((directory) => (
					<FileUploadFileViewerDirectory
						directory={directory}
						key={directory.name}
						isSubmitting={isSubmitting}
						isUploaded={isUploaded}
					/>
				))}
			</VStack>
		</VStack>
	);
};
