import {
	PickConstructionSiteForm,
	type PickConstructionSiteFormType,
} from "@/forms/pick-construction-site-form";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
} from "@msuite/picasso";
import type { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface PickConstructionSiteModalProps {
	isOpen: boolean;
	onClose: () => void;
	onComplete: (constructionSiteId: string) => void;
}

export const PickConstructionSiteModal: FC<PickConstructionSiteModalProps> = ({
	isOpen,
	onClose,
	onComplete,
}) => {
	/** Hooks */
	const methods = useForm<PickConstructionSiteFormType>();

	/** Functions */
	async function onSubmit(data: PickConstructionSiteFormType) {
		try {
			onComplete(data.constructionSiteId);
			handleOnClose();
		} catch (error) {
			toast.error("Es ist ein Fehler aufgetreten");
			console.error(error);
		}
	}

	function handleOnClose() {
		methods.reset();
		onClose();
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Bauvorhaben auswählen</ModalHeader>
						<ModalBody>
							<PickConstructionSiteForm />
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={handleOnClose}
								>
									abbrechen
								</Button>
								<Button type="submit">Auswählen</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};
