import { storage } from "@/core";
import {
	Button,
	Grid,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	VStack,
} from "@msuite/picasso";
import { ref, uploadBytes } from "firebase/storage";
import { type FC, useState } from "react";
import { Droparea } from "./droparea";
import { DropareaClick } from "./droparea-click";
import { FileUploadFileViewer } from "./file-upload-file-viewer";
import { UploadOverview } from "./upload-overview";

/** Props Interface */
interface FileUploadModalProps {
	basePath: string;
	isOpen: boolean;
	onClose: () => void;
	isDirectoryUpload?: boolean;
	newFiles: File[];
	setNewFiles: (files: File[]) => void;
	beforeUpload?: (files: File[]) => Promise<File[]>;
	onComplete?: (files: File[]) => Promise<void>;
}

export const FileUploadModal: FC<FileUploadModalProps> = ({
	basePath,
	isOpen,
	onClose,
	newFiles,
	setNewFiles,
	beforeUpload,
	onComplete,
}) => {
	/** State */
	const [isLoading, setIsLoading] = useState(false);
	const [isUploaded, setIsUploaded] = useState<string[]>([]);

	/** Functions */
	function handleOnFilesChange(files: File[]) {
		const _newFiles = files.filter((file) => !file.name.startsWith("."));
		setNewFiles(_newFiles);
	}

	/** Functions */
	async function handleOnSubmit() {
		try {
			setIsLoading(true);
			const files = beforeUpload ? await beforeUpload?.(newFiles) : newFiles;
			const storageRef = ref(storage, basePath);
			const promises = [];
			for (const file of files) {
				const fileRef = ref(storageRef, file.webkitRelativePath || file.name);
				promises.push(
					uploadBytes(fileRef, file).then(() => {
						setIsUploaded((state) => [...state, file.name]);
					}),
				);
			}
			await Promise.all(promises);
			await onComplete?.(files);
			setIsUploaded([]);
			onClose();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader>Dateien hochladen</ModalHeader>
				<ModalBody>
					<Grid
						gridTemplateColumns="1fr 1fr"
						width="100%"
						gap={4}
					>
						<Droparea handleOnFilesChange={handleOnFilesChange} />
						<DropareaClick
							handleOnFilesChange={handleOnFilesChange}
							isFolderPicker
						/>
						<DropareaClick handleOnFilesChange={handleOnFilesChange} />
					</Grid>
					<FileUploadFileViewer
						files={newFiles}
						isSubmitting={isLoading}
						isUploaded={isUploaded}
					/>
				</ModalBody>
				<ModalFooter>
					<VStack>
						{isLoading && (
							<UploadOverview
								uploadedFiles={isUploaded.length}
								maxFiles={newFiles.length}
							/>
						)}
						<ModalButtonGroup>
							<Button
								variant="ghost"
								onClick={onClose}
							>
								abbrechen
							</Button>
							<Button
								onClick={handleOnSubmit}
								isLoading={isLoading}
							>
								Hochladen
							</Button>
						</ModalButtonGroup>
					</VStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
