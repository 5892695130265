import { useConstructionSiteCardContext } from "@/components/card/construction-site-card/context";
import { db } from "@/core";
import { type ConstructionSite, type Ticket, type TicketTypes, generateId } from "@msuite/katana";
import {
	type ContextMenuOptions,
	TicketTypeIcon,
	toast,
	type useDisclosure,
} from "@msuite/picasso";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { IoCloudUpload, IoCopy, IoInformation, IoPaperPlane, IoPencil } from "react-icons/io5";
import type { ContextMenuType } from "../construction-site-card/construction-site-card-content";
import { ticketTypeArray } from "./ticket-card";

export const useContextMenu = (
	contextMenuType: ContextMenuType,
	ticket: Ticket,
	constructionSite: ConstructionSite | undefined,
	fileGalleryModal: ReturnType<typeof useDisclosure>,
) => {
	/** Context */
	const { editConstructionSiteModal } = useConstructionSiteCardContext();

	/** Constants */
	const ticketId = ticket.id;
	const constructionSiteId = constructionSite?.id;

	/** Functions */
	async function handleCopyTicket(type: TicketTypes) {
		try {
			const ticketCopy = { ...ticket, type };
			ticketCopy.id = generateId();
			const constructionSiteRef = doc(db, `baustellen/${constructionSiteId}`);
			await updateDoc(constructionSiteRef, {
				tickets: arrayUnion(ticketCopy),
			});
			toast.success("Ticket wurde als kopiert.");
		} catch (error) {
			console.error(error);
		}
	}

	async function openInformation() {
		try {
			window.open(`/construction-sites/${constructionSiteId}`, "_blank");
		} catch (error) {
			console.error(error);
		}
	}

	function openGoogleMaps() {
		try {
			const baseURL = "https://www.google.com/maps/search/?api=1&query=";
			const { strasse, hausnummer, plz, ort } = constructionSite ?? {};
			window.open(`${baseURL}${strasse}+${hausnummer}+${plz}+${ort}`, "_blank");
		} catch (error) {
			console.error(error);
		}
	}

	function handleOnOpenEditTicket() {
		try {
			localStorage.setItem("construction-site-edit-initial-ticket", ticketId);
			editConstructionSiteModal.onOpen();
		} catch (error) {
			console.error(error);
		}
	}

	/** Builders */
	function createDailyTicketContextMenuOptions(): ContextMenuOptions {
		const options: ContextMenuOptions = [
			{
				label: "Informationen",
				onClick: openInformation,
				icon: <IoInformation />,
			},
			{
				label: "Dateien hochladen",
				onClick: fileGalleryModal.onOpen,
				icon: <IoCloudUpload />,
			},
			"divider",
			{
				label: "Bearbeiten",
				onClick: handleOnOpenEditTicket,
				icon: <IoPencil />,
			},
			"divider",
			{
				label: "Auf Google Maps anzeigen",
				onClick: openGoogleMaps,
				icon: <IoPaperPlane />,
			},
		];
		return options;
	}

	function createWeeklyTicketContextMenuOptions(): ContextMenuOptions {
		const options: ContextMenuOptions = [
			{
				label: "Informationen",
				onClick: openInformation,
				icon: <IoInformation />,
			},
			{
				label: "Dateien hochladen",
				onClick: fileGalleryModal.onOpen,
				icon: <IoCloudUpload />,
			},
			"divider",
			{
				label: "Bearbeiten",
				onClick: handleOnOpenEditTicket,
				icon: <IoPencil />,
			},
			{
				label: "Kopieren",
				icon: <IoCopy />,
				subOptions: ticketTypeArray.map((type) => ({
					label: type,
					icon: <TicketTypeIcon type={type} />,
					onClick: () => handleCopyTicket(type),
				})),
			},
			"divider",
			{
				label: "Auf Google Maps anzeigen",
				onClick: openGoogleMaps,
				icon: <IoPaperPlane />,
			},
		];
		return options;
	}

	function createContextMenuOptions(contextType: ContextMenuType): ContextMenuOptions | undefined {
		if (contextType === "daily") return createDailyTicketContextMenuOptions();
		if (contextType === "weekly") return createWeeklyTicketContextMenuOptions();
		return undefined;
	}
	/** Constants */
	const contextMenuOptions = createContextMenuOptions(contextMenuType);

	return {
		contextMenuOptions,
	};
};
