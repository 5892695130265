import { SurveyContext } from "@/components/survey/context";
import type { ISurvey } from "@msuite/katana";
import { Box } from "@msuite/picasso";
import type { FC } from "react";
import { SurveyControls } from "./survey-controls";
import { SurveyDisplay } from "./survey-display";
import { useSurvey } from "./use-survey";

/** Props Interface */
interface SurveyContentProps {
	survey: ISurvey;
	onCancel: () => void;
}

export const SurveyContent: FC<SurveyContentProps> = ({ survey, onCancel }) => {
	/** Hooks */
	const surveyContext = useSurvey(survey);

	/** Render */
	return (
		<SurveyContext.Provider value={{ ...surveyContext }}>
			<SurveyControls onCancel={onCancel} />
			<Box
				rounded="lg"
				alignSelf="center"
				sx={{ width: "100%", aspectRatio: "16 / 9" }}
				position="relative"
				borderWidth={1}
			>
				<SurveyDisplay />
			</Box>
		</SurveyContext.Provider>
	);
};
