import { db } from "@/core";
import {
	type Employee,
	type ProjectRequestTimelineActivityEntry,
	type ProjectRequestTimelineCreationEntry,
	type ProjectRequestTimelineEntry,
	type ProjectRequestTimelineFileDeleteEntry,
	type ProjectRequestTimelineFileUploadEntry,
	type ProjectRequestTimelineFinalResultEntry,
	type ProjectRequestTimelineNoteEntry,
	type ProjectRequestTimelineReopenEntry,
	type ProjectRequestTimelineStateChangeEntry,
	moment,
} from "@msuite/katana";
import { Grid, HStack, Label, Text, VStack, useDocument } from "@msuite/picasso";
import type { FC } from "react";
import { TimelineActivityEntry } from "./entry/timeline-activity-entry";
import { TimelineCreationEntry } from "./entry/timeline-creation-entry";
import { TimelineFileDeleteEntry } from "./entry/timeline-file-delete-entry";
import { TimelineFileUploadEntry } from "./entry/timeline-file-upload-entry";
import { TimelineFinalResultEntry } from "./entry/timeline-final-result-entry";
import { TimelineNoteEntry } from "./entry/timeline-note-entry";
import { TimelineReopenEntry } from "./entry/timeline-reopen-entry";
import { TimelineStateChangeEntry } from "./entry/timeline-state-change-entry";
import { TimelineIconLine } from "./timeline-icon-line";
import { TimelineItemWrapper } from "./timeline-item-wrapper";

/** Props Interface */
interface TimelineItemProps {
	item: ProjectRequestTimelineEntry;
	projectRequestId: string;
}

export const TimelineItem: FC<TimelineItemProps> = ({ item, projectRequestId }) => {
	/** Hooks */
	const { data: employee } = useDocument<Employee>(db, {
		path: `mitarbeiter/${item.createdBy}`,
		subscribe: false,
	});

	/** Render */
	return (
		<Grid
			templateColumns="60px 1fr"
			style={{
				contentVisibility: "auto",
			}}
			_last={{
				"#timeline-icon-line-background": {
					opacity: 0,
				},
				marginBottom: "10vh",
			}}
		>
			<TimelineIconLine entry={item} />
			<TimelineItemWrapper>
				<HStack spacing={1}>
					<Label>{moment(item.createdAt).format("dddd, L [um] HH:mm:ss [Uhr]")}</Label>
					<Text fontSize="sm">
						&bull; {employee?.vorname} {employee?.nachname}
					</Text>
				</HStack>
				<VStack mt={2}>
					{item.type === "file-upload" && (
						<TimelineFileUploadEntry
							entry={item as ProjectRequestTimelineFileUploadEntry}
							projectRequestId={projectRequestId}
						/>
					)}
					{item.type === "file-delete" && (
						<TimelineFileDeleteEntry
							entry={item as ProjectRequestTimelineFileDeleteEntry}
							projectRequestId={projectRequestId}
						/>
					)}
					{item.type === "state-change" && (
						<TimelineStateChangeEntry entry={item as ProjectRequestTimelineStateChangeEntry} />
					)}
					{item.type === "creation" && (
						<TimelineCreationEntry entry={item as ProjectRequestTimelineCreationEntry} />
					)}
					{item.type === "note" && (
						<TimelineNoteEntry
							entry={item as ProjectRequestTimelineNoteEntry & { id: string }}
							projectRequestId={projectRequestId}
						/>
					)}
					{item.type === "activity" && (
						<TimelineActivityEntry
							entry={item as ProjectRequestTimelineActivityEntry & { id: string }}
							projectRequestId={projectRequestId}
						/>
					)}
					{item.type === "final-result" && (
						<TimelineFinalResultEntry
							entry={item as ProjectRequestTimelineFinalResultEntry & { id: string }}
							projectRequestId={projectRequestId}
						/>
					)}
					{item.type === "reopen" && (
						<TimelineReopenEntry
							entry={item as ProjectRequestTimelineReopenEntry & { id: string }}
							projectRequestId={projectRequestId}
						/>
					)}
				</VStack>
			</TimelineItemWrapper>
		</Grid>
	);
};
