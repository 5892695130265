import { ax } from "@/core";
import { ContactForm } from "@/forms/construction-site-contact-form/construction-site-contact-contacts-form";
import {
	type IConstructionSiteContactForm,
	contactOnlyShape,
} from "@/forms/construction-site-contact-form/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import type { EmployeeAuthorization } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
} from "@msuite/picasso";
import type { FC } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

/** Props Interface */
interface AddConstructionSiteContactContactModalProps {
	isOpen: boolean;
	onClose: () => void;
	contactId: string;
	onComplete?: (contactContactId: string) => void;
}

export const AddConstructionSiteContactContactModal: FC<
	AddConstructionSiteContactContactModalProps
> = ({ onClose, isOpen, contactId, onComplete }) => {
	/* Hooks */
	const methods = useForm<IConstructionSiteContactForm>({
		reValidateMode: "onChange",
		defaultValues: {
			contacts: [
				{
					authorizations: [] as EmployeeAuthorization[],
				} as Required<IConstructionSiteContactForm>["contacts"][number],
			],
		},
		resolver: yupResolver(contactOnlyShape) as any,
	});
	const fieldArray = useFieldArray({ control: methods.control, name: "contacts" });

	/** Functions */
	function handleOnClose() {
		onClose();
		methods.reset();
	}

	async function handleOnSubmit(_values: IConstructionSiteContactForm) {
		try {
			const values = _values.contacts?.at(0);
			if (!values) throw new Error("Kontakt konnte nicht erstellt werden.");
			await ax.post(`/v2/contacts/${contactId}/contacts`, { data: { values } });
			handleOnClose();
			onComplete?.(values.id);
		} catch (error) {
			toast.error("Kontakt konnte nicht erstellt werden.");
			console.error(error);
		}
	}

	async function onReject() {
		toast.error("Fehlgeschlagen");
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
			>
				<ModalOverlay />
				<form
					onSubmit={(e) => {
						e.preventDefault();
						e.stopPropagation();
						methods.handleSubmit(handleOnSubmit, onReject)(e);
					}}
				>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Kontakt hinzufügen</ModalHeader>
						<ModalBody>
							{fieldArray.fields.map((field, index) => (
								<ContactForm
									methods={methods}
									isSingleEntry
									field={field}
									index={index}
									fieldArray={fieldArray}
									key={field.id}
								/>
							))}
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									onClick={onClose}
									variant="ghost"
								>
									abbrechen
								</Button>
								<Button
									type="submit"
									isLoading={methods.formState.isSubmitting}
								>
									Anlegen
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};
