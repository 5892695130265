import { db } from "@/core";
import { ConstructionSiteContactForm } from "@/forms/construction-site-contact-form";
import { ConstructionSiteContactContactsForm } from "@/forms/construction-site-contact-form/construction-site-contact-contacts-form";
import {
	type IConstructionSiteContactForm,
	constructionSiteContactFormSchema,
	constructionSiteContactFormSchemaLight,
} from "@/forms/construction-site-contact-form/schema";
import { EditConstructionSiteContactModalContext } from "@/modals/construction-sites/edit-construction-site-contact-modal/context";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	type ConstructionSiteContact,
	type ConstructionSiteContactOverride,
	generateId,
	mergeObjects,
} from "@msuite/katana";
import {
	Button,
	HStack,
	IconButton,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	ModalTitle,
	SegmentedControl,
	VStack,
	findFirstErrorMessage,
	toast,
} from "@msuite/picasso";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { type FC, useState } from "react";
import { FormProvider, useFieldArray, useForm, type useFormState } from "react-hook-form";
import { TbPlus } from "react-icons/tb";
import { handleOverride } from "../edit-construction-site-contact-modal";
import { ConstructionSiteContactSearch } from "./construction-site-contact-search";

/** Props Interface */
interface AddConstructionSiteContactModalProps {
	isOpen: boolean;
	onClose: () => void;
	onComplete?: (contactId: string) => void;
	defaultValues?: Partial<IConstructionSiteContactForm>;
	withLightResolver?: boolean;
	withExistingSearchBox?: boolean;
	constructionSiteId?: string;
}

export const AddConstructionSiteContactModal: FC<AddConstructionSiteContactModalProps> = ({
	isOpen,
	onClose,
	defaultValues,
	onComplete,
	withLightResolver,
	withExistingSearchBox,
	constructionSiteId,
}) => {
	/** State */
	const [preSelectedId, setPreSelectedId] = useState<string | undefined>();
	const [visibleContacts, setVisibleContacts] = useState<string[]>([]);
	const [currentView, setCurrentView] = useState<"search" | "company" | "contacts">(
		withExistingSearchBox ? "search" : "company",
	);
	const shouldOverride = !!preSelectedId;

	/** Hooks */
	const methods = useForm<IConstructionSiteContactForm>({
		defaultValues,
		// @ts-ignore
		resolver: yupResolver(
			withLightResolver
				? (constructionSiteContactFormSchemaLight(true) as any)
				: (constructionSiteContactFormSchema(shouldOverride) as any),
		),
	});
	const contactsArray = useFieldArray({
		control: methods.control,
		name: "contacts",
		keyName: "_id",
	});

	/** Functions */
	async function onSubmit(data: IConstructionSiteContactForm) {
		try {
			let finalId: string | undefined;
			if (preSelectedId) {
				finalId = preSelectedId;
				if (!constructionSiteId) throw new Error("constructionSiteId is required");
				await handleOverride(data, preSelectedId, constructionSiteId, visibleContacts);
			} else {
				const randomId = generateId();
				finalId = randomId;
				const contactsRef = doc(db, `_contactBook/${finalId}`);
				await setDoc(contactsRef, data);
			}
			toast.success("Kontakt wurde erfolgreich angelegt.");
			onComplete?.(finalId);
			handleOnClose(true);
		} catch (error) {
			console.error(error);
		}
	}

	function handleOnClose(skipCheck?: boolean) {
		if (methods.formState.isDirty && !skipCheck) {
			const confirm = window.confirm("Möchten Sie die Änderungen verwerfen?");
			if (!confirm) return;
		}
		methods.reset();
		onClose();
	}

	function handleAddContact() {
		const id = generateId();
		if (preSelectedId) {
			setVisibleContacts([...visibleContacts, id]);
		}
		contactsArray.append({
			id,
			type: "mr",
			firstName: "",
			lastName: "",
			phone: "",
			email: "",
			status: "unknown",
			authorizations: [],
			invoiceDistributor: false,
		});
	}

	async function onReject(e: ReturnType<typeof useFormState>["errors"]) {
		const firstErrorMessage = findFirstErrorMessage(e);
		toast.error(firstErrorMessage);
	}

	async function handleSelectExistingContact(contactId: string, contact: ConstructionSiteContact) {
		try {
			if (constructionSiteId) {
				const overrideRef = doc(
					db,
					`baustellen/${constructionSiteId}/contactOverrides/${contactId}`,
				);
				const override = (await getDoc(overrideRef)).data() as ConstructionSiteContactOverride;
				if (override) {
					const mergedContact = mergeObjects(contact, override.override);
					setVisibleContacts(override.visibleContacts || []);
					methods.reset(mergedContact);
					setPreSelectedId(contactId);
					setCurrentView("company");
				} else {
					plainContactSelect(contactId, contact);
				}
			} else {
				plainContactSelect(contactId, contact);
			}
		} catch (error) {
			toast.error("Es ist ein Fehler aufgetreten.");
			console.error(error);
		}
	}

	function plainContactSelect(contactId: string, contact: ConstructionSiteContact) {
		methods.reset(contact);
		setPreSelectedId(contactId);
		setCurrentView("company");
	}

	/** Computed */
	const segmentedControlOptions = [
		{ label: "Unternhemen", value: "company" },
		{ label: "Kontakte", value: "contacts" },
	];
	if (withExistingSearchBox) {
		segmentedControlOptions.unshift({ label: "Suche", value: "search" });
	}

	/** Render */
	return (
		<EditConstructionSiteContactModalContext.Provider
			value={{
				visibleContacts,
				setVisibleContacts,
				shouldOverride,
			}}
		>
			<FormProvider {...methods}>
				<Modal
					isOpen={isOpen}
					onClose={handleOnClose}
				>
					<ModalOverlay />
					<form
						onSubmit={(e) => {
							e.stopPropagation();
							e.preventDefault();
							methods.handleSubmit(onSubmit, onReject)(e);
						}}
					>
						<ModalContent>
							<ModalCloseButton />
							<ModalHeader>
								<VStack>
									<ModalTitle>Baustellenkontakt anlegen</ModalTitle>
									<HStack justify="space-between">
										<SegmentedControl
											value={currentView}
											onChange={setCurrentView}
											options={segmentedControlOptions}
										/>
										{currentView === "contacts" && (
											<IconButton
												size="xs"
												icon={<TbPlus />}
												onClick={handleAddContact}
												aria-label="add"
											/>
										)}
									</HStack>
								</VStack>
							</ModalHeader>
							<ModalBody>
								{currentView === "search" && (
									<ConstructionSiteContactSearch
										key="search-box"
										onSelect={handleSelectExistingContact}
									/>
								)}
								{currentView === "company" && <ConstructionSiteContactForm key="company-form" />}
								{currentView === "contacts" && (
									<ConstructionSiteContactContactsForm
										key="contacts-form"
										fieldArray={contactsArray}
									/>
								)}
							</ModalBody>
							<ModalFooter>
								<ModalButtonGroup>
									<Button
										variant="ghost"
										onClick={() => {
											handleOnClose();
										}}
									>
										abbrechen
									</Button>
									<Button
										type="submit"
										isLoading={methods.formState.isSubmitting}
									>
										Anlegen
									</Button>
								</ModalButtonGroup>
							</ModalFooter>
						</ModalContent>
					</form>
				</Modal>
			</FormProvider>
		</EditConstructionSiteContactModalContext.Provider>
	);
};
