import { db } from "@/core";
import type {
	ConstructionSiteContractDocument,
	ConstructionSiteDocument,
	Employee,
} from "@msuite/katana";
import { moment } from "@msuite/katana";
import { DocumentString } from "@msuite/picasso";
import { createColumnHelper } from "@tanstack/react-table";
import { DocumentName } from "./document-name";
import { RowActions } from "./row-actions";

const columnHelper = createColumnHelper<
	ConstructionSiteDocument | ConstructionSiteContractDocument
>();

export const columns = [
	columnHelper.accessor("file_name", {
		header: "Dateiname",
		cell: (cell) => <DocumentName document={cell.row.original} />,
		meta: {
			width: "35%",
		},
	}),
	columnHelper.accessor("created_by", {
		header: "Erstellt von",
		cell: (cell) => (
			<DocumentString<Employee>
				db={db}
				path={`mitarbeiter/${cell.getValue()}`}
				fields={["vorname", "nachname"]}
				shouldExtendSearch
				id={cell.getValue()}
			/>
		),
		meta: {
			width: "20%",
		},
	}),
	columnHelper.accessor("created_at", {
		header: "Erstellt am",
		cell: (cell) => moment(cell.getValue()).format("LLLL"),
		meta: {
			width: "20%",
		},
	}),
	columnHelper.display({
		id: "actions",
		header: "",
		cell: (cell) => <RowActions document={cell.row.original} />,
	}),
];
