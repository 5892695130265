import { AutoCompleteItem } from "@/components/construction-site";
import { ax } from "@/core";
import { createConstructionSiteAutoCompleteOptions } from "@/util/construction-site";
import type { ConstructionSite, IGetConstructionSitesByWorkspaceApiResponse } from "@msuite/katana";
import {
	AutoComplete,
	type AutoCompleteOption,
	FormControl,
	FormLabel,
	useApi,
} from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormError, type PickConstructionSiteFormType } from "./schema";

export const PickConstructionSiteForm: FC = () => {
	/** Context */
	const methods = useFormContext<PickConstructionSiteFormType>();
	const { data } = useApi<IGetConstructionSitesByWorkspaceApiResponse>(ax, {
		url: "/v2/construction-sites/all?fields=id,nummer,bauvorhaben,strasse,plz,ort,hausnummer",
		dependencies: ["construction-sites"],
	});

	const options = createConstructionSiteAutoCompleteOptions(data?.constructionSites ?? []);

	function handleOnSelect(value: AutoCompleteOption<ConstructionSite> | undefined) {
		if (!value?.item?.id) return;
		methods.setValue("constructionSiteId", value.item.id);
	}

	/** Render */
	return (
		<FormControl>
			<FormLabel>Bauvorhaben</FormLabel>
			<AutoComplete<ConstructionSite>
				placeholder="Bauvorhaben suchen"
				options={options}
				renderItem={AutoCompleteItem}
				onChange={handleOnSelect}
			/>
			<FormError name="constructionSiteId" />
		</FormControl>
	);
};
