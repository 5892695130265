import { db } from "@/core";
import { AddConstructionSiteContactContactModal } from "@/modals/construction-sites/add-construction-site-contact-contact-modal";
import type { ConstructionSiteContact } from "@msuite/katana";
import {
	Box,
	Checkbox,
	Collapser,
	Grid,
	HStack,
	IconButton,
	Label,
	Portal,
	Text,
	VStack,
	useDisclosure,
	useDocument,
	useUIContext,
} from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { TbPlus, TbStar, TbStarFilled } from "react-icons/tb";
import type { ProjectRequestFormType } from "./schema";

/** Props Interface */
interface ProjectRequestFormClientContactsProps {
	contactId: string | undefined;
	type: "client" | "response-client";
}

export const ProjectRequestFormClientContacts: FC<ProjectRequestFormClientContactsProps> = ({
	contactId,
	type,
}) => {
	/** Context */
	const { colors } = useUIContext();
	const methods = useFormContext<ProjectRequestFormType>();
	const contacts = useWatch({
		control: methods.control,
		name: type === "client" ? "clientContacts" : "responseClientContacts",
	});

	/** Hooks */
	const collapser = useDisclosure({ defaultIsOpen: true });
	const addConstructionSiteContactContactModal = useDisclosure();
	const { data } = useDocument<ConstructionSiteContact>(db, {
		path: `_contactBook/${contactId}`,
		subscribe: true,
		queryClientOptions: {
			enabled: !!contactId,
		},
	});

	/** Guard */
	if (!contactId) return null;

	/** Functions */
	function getContactIsActive(contactId: string) {
		return contacts?.some((c) => c.contactId === contactId);
	}

	function handleOnToggleContact(contactId: string) {
		if (getContactIsActive(contactId)) {
			methods.setValue(
				type === "client" ? "clientContacts" : "responseClientContacts",
				contacts?.filter((c) => c.contactId !== contactId),
			);
		} else {
			methods.setValue(type === "client" ? "clientContacts" : "responseClientContacts", [
				...(contacts || []),
				{ contactId, isPrimary: !contacts?.length },
			]);
		}
	}

	function handleOnToggleIsPrimary(contactId: string) {
		methods.setValue(
			type === "client" ? "clientContacts" : "responseClientContacts",
			contacts?.map((c) => {
				if (c.contactId === contactId) {
					return { ...c, isPrimary: !c.isPrimary };
				}
				return c;
			}),
		);
	}

	/** Render */
	return (
		<Fragment>
			<Grid
				paddingLeft={12}
				templateColumns="max-content 1fr"
				placeItems="stretch"
			>
				<Box alignSelf="start">
					<Collapser
						isCollapsed={!collapser.isOpen}
						onToggle={collapser.onToggle}
					/>
				</Box>
				<VStack spacing={2}>
					<HStack justifyContent="space-between">
						<Label
							cursor="pointer"
							onClick={collapser.onToggle}
						>
							{data?.contacts?.length ?? 0} Kontakte
						</Label>
						<IconButton
							onClick={addConstructionSiteContactContactModal.onOpen}
							text="Kontakt hinzufügen"
							aria-label="Kontakt hinzufügen"
							icon={<TbPlus />}
							size="xs"
						/>
					</HStack>
					{collapser.isOpen && (
						<VStack paddingTop={2}>
							{data?.contacts?.map((contact) => {
								const isActive = getContactIsActive(contact.id);
								const isPrimary = contacts?.some((c) => c.contactId === contact.id && c.isPrimary);
								return (
									<HStack
										key={contact.id}
										justifyContent="space-between"
									>
										<Checkbox
											size="md"
											isChecked={isActive}
											onChange={() => {
												handleOnToggleContact(contact.id);
											}}
										>
											{contact.firstName} {contact.lastName}
										</Checkbox>
										{isActive && (
											<IconButton
												cursor="pointer"
												onClick={() => {
													handleOnToggleIsPrimary(contact.id);
												}}
												size="xs"
												color={isPrimary ? colors.orange : undefined}
												icon={isPrimary ? <TbStarFilled /> : <TbStar />}
												aria-label="Hauptkontakt"
											/>
										)}
									</HStack>
								);
							})}
							<HStack
								spacing={1}
								alignItems="center"
								justifyContent="flex-end"
							>
								<Text
									fontSize="xs"
									color={colors.orange}
								>
									<TbStarFilled />
								</Text>
								<Text fontSize="sm">= Hauptkontakt</Text>
							</HStack>
						</VStack>
					)}
				</VStack>
			</Grid>
			<Portal>
				<AddConstructionSiteContactContactModal
					isOpen={addConstructionSiteContactContactModal.isOpen}
					onClose={addConstructionSiteContactContactModal.onClose}
					contactId={contactId}
					onComplete={handleOnToggleContact}
				/>
			</Portal>
		</Fragment>
	);
};
