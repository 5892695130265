import type { FileNamesDocumentTree } from "@msuite/katana";
import { VStack } from "@msuite/picasso";
import type { FC } from "react";
import { FileNamesTreeDirectory } from "./file-names-tree-directory";

/** Props Interface */
interface FileNamesTreeProps {
	tree: FileNamesDocumentTree;
	basePath: string;
}

export const FileNamesTree: FC<FileNamesTreeProps> = ({ tree, basePath }) => {
	/** Render */
	return (
		<VStack spacing={2}>
			<FileNamesTreeDirectory
				directory={tree}
				basePath={basePath}
			/>
		</VStack>
	);
};
