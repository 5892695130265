import { useConstructionSiteCardContext } from "@/components/card/construction-site-card/context";
import { AssignTicketDatePopover } from "@/components/popover";
import type { TicketDateForm } from "@/components/popover/assign-ticket-date-popover/schema";
import { db } from "@/core";
import { usePlanContext } from "@/sites/plan/context";
import { getWeeklyReferenceString } from "@/sites/plan/use-plan";
import { cleanUndefinedValues, moment } from "@msuite/katana";
import { toast, useAuthContext, useUIContext } from "@msuite/picasso";
import { arrayUnion, doc, runTransaction, setDoc, updateDoc } from "firebase/firestore";
import type { FC } from "react";
import { useTicketCardContext, useTicketsContext } from "../../context";
import { ActionBarButton } from "../action-bar-button";
import { handleOnNotAssign } from "../not-assign-button";

export const AssignDateButton: FC = () => {
	/** Context */
	const { activeDate, weeklyReference, activeDailyInfo, activeIsBlocked, activeWorkspace } =
		usePlanContext();
	const { constructionSite, constructionSiteId, withMultiSelect, isInView } =
		useConstructionSiteCardContext();
	const { ticket } = useTicketCardContext();
	const { filteredTickets, setSelectedTicketIndices, selectedTicketIndices } = useTicketsContext();
	const { validation } = useAuthContext();

	/** Constants */
	const { colors } = useUIContext();

	/** Functions */
	async function handleMultiSelect(updateValue: TicketDateForm) {
		if (withMultiSelect && selectedTicketIndices?.length > 0) {
			const ticketIds = filteredTickets
				.map((ticket) => ticket.id)
				.filter((_, index) => selectedTicketIndices.includes(index));
			await handleOnUpdate(updateValue, ticketIds);
		} else {
			await handleOnUpdate(updateValue, [ticket.id]);
		}
		setSelectedTicketIndices([]);
	}

	async function handleOnUpdate(data: TicketDateForm, ticketIds: string[]) {
		try {
			const newTickets = [...(constructionSite?.tickets ?? [])];
			for (const ticketId of ticketIds) {
				const ticketIndex = newTickets.findIndex(({ id }) => id === ticketId);
				if (ticketIndex === undefined || ticketIndex === -1) throw new Error("Ticket not found");
				if (data.date !== undefined) newTickets[ticketIndex].date = data.date;
				if (data.dateIsDeadline !== undefined)
					newTickets[ticketIndex].date_is_deadline = data.dateIsDeadline;
				if (data.dateEarliestFrom !== undefined)
					newTickets[ticketIndex].date_earliest_from = data.dateEarliestFrom;
				if (!data.withEarliestFrom) newTickets[ticketIndex].date_earliest_from = undefined;
				newTickets[ticketIndex] = cleanUndefinedValues(newTickets[ticketIndex]);
			}
			const constructionSiteRef = doc(db, `baustellen/${constructionSiteId}`);
			await updateDoc(constructionSiteRef, { tickets: newTickets });
			handleFurtherAssignment(data, ticketIds);
		} catch (error) {
			console.error(error);
		}
	}

	async function handleMultiSelectRemove() {
		if (withMultiSelect && selectedTicketIndices?.length > 0) {
			const ticketIds = filteredTickets
				.map((ticket) => ticket.id)
				.filter((_, index) => selectedTicketIndices.includes(index));
			await handleOnRemove(ticketIds);
		} else {
			await handleOnRemove([ticket.id]);
		}
		setSelectedTicketIndices([]);
	}

	async function handleOnRemove(ticketIds: string[]) {
		try {
			const constructionSiteRef = doc(db, `baustellen/${constructionSiteId}`);
			if (!constructionSite?.tickets) throw new Error("No tickets found");
			const tickets = [...(constructionSite?.tickets ?? [])];
			for (const ticketId of ticketIds) {
				const ticketIndex = constructionSite?.tickets?.findIndex(({ id }) => id === ticketId);
				if (ticketIndex === undefined || ticketIndex === -1) throw new Error("Ticket not found");
				tickets[ticketIndex] = {
					...tickets[ticketIndex],
				};
				tickets[ticketIndex].date = undefined;
				tickets[ticketIndex].date_is_deadline = undefined;
			}
			await runTransaction(db, async (transaction) => {
				transaction.update(constructionSiteRef, { tickets });
			});
		} catch (error) {
			console.error(error);
		}
	}

	async function handleFurtherAssignment(updateValue: TicketDateForm, ticketIds: string[]) {
		try {
			const targetWeek = moment().convertTicketDateToMoment(updateValue.date);
			const weeklyRef = doc(db, getWeeklyReferenceString(activeWorkspace, targetWeek));

			if (!activeIsBlocked && validation?.isAdmin) {
				await handleOnNotAssign({
					ticketIds,
					activeDailyInfo,
					weeklyReference,
					activeDate,
					forceNa: true,
				});
			}

			await updateDoc(weeklyRef, {
				baustellen: arrayUnion(constructionSiteId),
			}).catch(async () => {
				await setDoc(weeklyRef, {
					baustellen: [constructionSiteId],
				});
			});
		} catch (error) {
			console.error(error);
			toast.error("Fehler beim Zuweisen der Woche.");
		}
	}

	/** Render */
	return (
		<AssignTicketDatePopover
			key={JSON.stringify(ticket)}
			currentTicket={ticket}
			onSubmit={handleMultiSelect}
			onRemove={handleMultiSelectRemove}
			shouldRender={isInView}
		>
			<div>
				<ActionBarButton color={colors.blue} />
			</div>
		</AssignTicketDatePopover>
	);
};
