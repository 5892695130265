import { ax, db } from "@/core";
import { AbsenceForm } from "@/forms/absence-form";
import { absenceFormSchema } from "@/forms/absence-form/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import type { Absence } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
} from "@msuite/picasso";
import { doc, getDoc } from "firebase/firestore";
import type { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface EditAbsenceModalProps {
	isOpen: boolean;
	onClose: () => void;
	employeeId: string;
	absenceId: string;
	onComplete?: () => Promise<unknown>;
}

export const EditAbsenceModal: FC<EditAbsenceModalProps> = ({
	employeeId,
	absenceId,
	isOpen,
	onClose,
	onComplete,
}) => {
	/** Hooks */
	const methods = useForm<Partial<Absence>>({
		resolver: yupResolver(absenceFormSchema(false) as any) as any,
		mode: "onChange",
		defaultValues: async () => {
			const absenceRef = doc(db, `mitarbeiter/${employeeId}/abwesenheit/${absenceId}`);
			const absence = (await getDoc(absenceRef)).data() as Absence;
			absence.id = absenceId;
			return absence;
		},
	});

	/** Functions */
	async function handleOnSubmit(values: Partial<Absence>) {
		try {
			await ax.patch(`/v2/employees/${values.mitarbeiterId}/absence/${absenceId}`, {
				data: { values },
			});
			onClose();
			toast.success("Abwesenheit aktualisiert");
			onComplete?.();
		} catch (error) {
			console.error(error);
			toast.error("Fehler beim Aktualisieren der Abwesenheit");
			methods.setValue("category", undefined);
		}
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleOnSubmit)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Antrag bearbeiten</ModalHeader>
						<ModalBody>
							<AbsenceForm hasEmployeeSelection={false} />
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={onClose}
								>
									abbrechen
								</Button>
								<Button
									isLoading={methods.formState.isSubmitting}
									type="submit"
								>
									Aktualisieren
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};
