import { db } from "@/core";
import type { Employee } from "@msuite/katana";
import { Avatar, HStack, Text, Tooltip, useDocument } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface EmployeeAvatarProps {
	employeeId: string | undefined;
	withName?: boolean;
}

export const EmployeeAvatar: FC<EmployeeAvatarProps> = ({ employeeId, withName }) => {
	/** Hooks */
	const { data: employee } = useDocument<Employee>(db, {
		path: `mitarbeiter/${employeeId}`,
		subscribe: false,
		queryClientOptions: {
			enabled: !!employeeId,
		},
	});

	/** Guard */
	if (!employeeId) return null;

	/** Render */
	return (
		<HStack spacing={2}>
			<Tooltip
				label={`${employee?.vorname} ${employee?.nachname}`}
				isDisabled={withName}
			>
				<div>
					<Avatar
						size="xs"
						initials={employee?.kurz}
					/>
				</div>
			</Tooltip>
			{withName && (
				<Text>
					{employee?.vorname} {employee?.nachname}
				</Text>
			)}
		</HStack>
	);
};
