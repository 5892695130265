import { FileUpload } from "@/components/file-upload";
import { ax } from "@/core";
import { Portal } from "@msuite/picasso";
import { type FC, useMemo } from "react";

/** Props Interface */
interface HeaderProps {
	headerRef: React.RefObject<HTMLDivElement>;
	/**
	 * projectRequestId or constructionSiteId;
	 */
	id: string;
	type: "project-request" | "construction-site-documents" | "construction-site-contract-documents";
}

/**
 * The server endpoint expects following data
 * const id = req.body.data.id;
 * const type = req.body.data.type as "construction-site" | "project-request";
 * const files = req.body.data.files as Array<{ path: string }>;
 * const path = req.body.data.path;
 * -> path equals projectRequests/${projectRequestId}/documents or baustellen/${constructionSiteId}/documents
 */

export const Header: FC<HeaderProps> = ({ headerRef, id, type }) => {
	/** Memoized */
	const path = useMemo(() => {
		if (type === "project-request") {
			return `projectRequests/${id}/documents`;
		}
		if (type === "construction-site-documents") {
			return `baustellen/${id}/documents`;
		}
		if (type === "construction-site-contract-documents") {
			return `baustellen/${id}/contract_documents`;
		}
	}, [type, id]);

	/** Functions */
	async function handleOnCompleteFileUpload(files: File[]) {
		try {
			await ax.post("/v2/construction-sites/documents", {
				data: { files, path, id, type },
			});
		} catch (error) {
			console.error(error);
		}
	}

	/** Guard */
	if (!path) return null;

	/** Render */
	return (
		<Portal containerRef={headerRef}>
			<FileUpload
				onComplete={handleOnCompleteFileUpload}
				basePath={path}
			/>
		</Portal>
	);
};
