import { Button, useDisclosure } from "@msuite/picasso";
import type { FC } from "react";
import React, { useState } from "react";
import { FileUploadModal } from "./file-upload-modal";

/** Props Interface */
interface FileUploadProps {
	basePath: string;
	beforeUpload?: (files: File[]) => Promise<File[]>;
	onComplete?: (files: File[]) => Promise<void>;
}

export const FileUpload: FC<FileUploadProps> = ({ basePath, beforeUpload, onComplete }) => {
	/** State */
	const [newFiles, setNewFiles] = useState<File[]>([]);

	/** Hooks */
	const uploadModal = useDisclosure();

	/** Functions */
	function handleOnClose() {
		setNewFiles([]);
		uploadModal.onClose();
	}

	/** Render */
	return (
		<React.Fragment>
			<Button onClick={uploadModal.onOpen}>Dateien hochladen</Button>
			<FileUploadModal
				basePath={basePath}
				isOpen={uploadModal.isOpen}
				onClose={handleOnClose}
				newFiles={newFiles}
				setNewFiles={setNewFiles}
				beforeUpload={beforeUpload}
				onComplete={onComplete}
			/>
		</React.Fragment>
	);
};
