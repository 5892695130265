import type { StatementCleanedSite } from "@msuite/katana";
import { createContext, useContext } from "react";
import type { TYearlyStatementModalView } from "./yearly-statement-modal";

/** Props Interface */
interface IYearlyStatementModalContext {
	currentView: TYearlyStatementModalView;
	setCurrentView: (view: TYearlyStatementModalView) => void;
	sites: Array<StatementCleanedSite> | undefined;
}

/** Context */
export const YearlyStatementModalContext = createContext<IYearlyStatementModalContext>(
	{} as IYearlyStatementModalContext,
);

/** Hooks */
export const useYearlyStatementModalContext = () => {
	return useContext(YearlyStatementModalContext);
};
