import { ax } from "@/core";
import {
	HandleSurveyModalContext,
	type HandleSurveysModalViewState,
} from "@/modals/surveys/handle-survey-modal/context";
import type { ISurvey } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	VStack,
	useApi,
	useAuthContext,
} from "@msuite/picasso";
import { type FC, useMemo, useState } from "react";
import { Content } from "./content";
import { SurveyList } from "./survey-list";

/** Props Interface */
interface HandleSurveysModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const HandleSurveysModal: FC<HandleSurveysModalProps> = ({ isOpen, onClose }) => {
	const { userId } = useAuthContext();

	const { data } = useApi<{ surveys: (ISurvey & { survey_path: string })[] }>(ax, {
		url: `/surveys/user/${userId}`,
		dependencies: ["surveys", userId ?? ""],
	});

	/** State */
	const [selectedSurvey, setSelectedSurvey] = useState<ISurvey | undefined>(undefined);
	const viewState: HandleSurveysModalViewState = useMemo(() => {
		if (selectedSurvey) return "survey";
		return "overview";
	}, [selectedSurvey]);

	/** Render */
	return (
		<HandleSurveyModalContext.Provider
			value={{
				viewState,
				surveys: data?.surveys ?? [],
				selectedSurvey,
				setSelectedSurvey,
			}}
		>
			<Modal
				isOpen={isOpen}
				onClose={() => {
					onClose();
					setSelectedSurvey(undefined);
				}}
			>
				<ModalOverlay />
				<ModalContent
					transition={viewState === "overview" ? "0.35s" : "0.35s"}
					width={viewState === "overview" ? "25vw" : "80vw"}
				>
					<ModalCloseButton />
					<ModalHeader>Offene Umfragen</ModalHeader>
					<ModalBody>
						<VStack>{viewState === "survey" ? <Content /> : <SurveyList />}</VStack>
					</ModalBody>
					{viewState === "overview" && (
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={onClose}
								>
									schließen
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					)}
				</ModalContent>
			</Modal>
		</HandleSurveyModalContext.Provider>
	);
};
